import React from 'react';
import {URL_BASE_WS} from '../Constanst'

const ReportActivityGListItem = (props) => {
    const {data, dates, apikey}=props;
    let color = data.status === 0 ? "table-light" : data.status === 1 ? "table-secondary" : "table-danger";
    return (
        <tr class={color}>
            <td>
                {
                    data.report ?
                    <a target='_blank' rel="noopener noreferrer" href={`${URL_BASE_WS}/reports/activities/responses/sheet?${dates}activityId=${data.activityId}&apikey=${apikey}`} >
                        {data.name}
                    </a>
                    :
                    <div>
                        {data.name}
                    </div>
                }
            </td>
            <td>{data.responseCount}</td>
        </tr>
    )
}
export default ReportActivityGListItem;
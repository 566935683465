import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {loadState, saveState} from './localStore'
import App from './App';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import reducer from './reducers'
import throttle from 'lodash.throttle';
import 'bootstrap/dist/css/bootstrap.css';
import './global.css';
import * as serviceWorker from './serviceWorker';


let initialState = loadState();

if(initialState === undefined ){
  initialState ={
    "user":{},
  }
}
if(Object.keys(initialState).length===0   ){
  initialState ={
    "user":{},
  }
}

const store = createStore(reducer,initialState);
store.subscribe(throttle(() => {
  console.log('store.subscribe: '+store.getState().todos);
  saveState(store.getState());
}, 1000));



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import qs from 'querystring'
import PageLoading from '../components/PageLoading';
import RouteModalUsers from '../components/RouteModalUsers';

import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import RouteList from '../components/RouteList';

const  Routes  = (props) =>  {

   
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const level = user.level;
    const permissions = Number(user.permissions);

    const [state,setState] = useState({
        loading: (permissions & 256) !== 0,
        error: null,
        data: [],
    });

    async function fetchRoutes() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/routes/active`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                //swal("Algo salio mal!", `${response.message}`, "error")
                //.then((value) => {
                    props.history.replace('/');
                //})                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.routes})
        }
    }

    useEffect( () => {
        console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        if (permissions & 256) {
            fetchRoutes();
        }
    },[]);


    const handleClickDetail = (e) => {
        let index  = e.currentTarget.dataset.index;
        

        if (Object.keys(state.data[index].locations).length>0){
            props.history.push({
                pathname: `/route/map`,
                state: { detail: state.data[index] }
              })
        } else {
            swal("Recorrido","No tiene ubicaciones", "error")
            
        }
        
    };

    //************Users************* */
    const [stateUsers,setStateUsers] = useState({ 
        modalIsOpen:false,
        data: []
    }) 

    const handleRecorrido = (e) => {
        console.log('handleRecorrido');
        fetchUsers();
    }

    const handleCloseModalUsers = (e) =>{
        setStateUsers({
            modalIsOpen:false,
            data: []
        })
    }

    const handleClickCheck = (e) => {
        let dataTemp=stateUsers.data;
        let actTemp= stateUsers.activityId;
        let itemUser=dataTemp[e.target.value];
        if(e.target.checked){
            itemUser.allowed=1;
        } else {
            itemUser.allowed=0;
        }
        dataTemp[e.target.value]=itemUser;
        setStateUsers({
            activityId:actTemp,
            modalIsOpen:true,
            data : dataTemp
        })
    }
    const handleSaveUsers =  async (e) => {
        try {
            let dataTemp=stateUsers.data;
            let dataAllowed = dataTemp.filter(function (item) {
                return item.allowed === 1;
            });
            let usersStr = dataAllowed.reduce((resp, item) => `${resp}${item.userId},` ,'')
            let formUsers = {
                users:  usersStr.substring(0, usersStr.length - 1)
            }
            console.log(formUsers);

            let response = await fetch(`${URL_BASE_WS}/routes/users`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(formUsers),
            });
            response = await response.json();
            console.log(response);
            //setState({loading:false})
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            } else {
                swal({title: "Recorrido solicitado!", icon: "success"}).then((value) => {
                    setStateUsers({
                        modalIsOpen:false,
                        data: []
                    })
                });
            }
        } catch(error){
            //setState({loading:false})
            swal("Algo salio mal!", `${error.message}`, "error")
        }

        //console.log(formUsers);


    }

    async function fetchUsers() {
        
        let response =  await fetch(`${URL_BASE_WS}/routes/users`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        console.log(response);
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                    props.history.replace('/');
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
        } else {
            setStateUsers({ modalIsOpen:true,data:response.users})
        }
    }


    

    if (state.loading === true) {
        return <PageLoading />;
    }
    
    return (
            <React.Fragment>
                <div className='container'>
                    <div className='row mb-3 mt-4 justify-content-start'>
                        <div className='col-4'>
                            <h2>Recorridos Activos</h2>
                        </div>
                        <div className='col-4'></div>
                        {
                            (permissions & 128) !== 0 ?
                                <div className='col-4 '>
                                    <button  className='btn btn-primary btn-sm' onClick={handleRecorrido}>
                                        <svg class="bi bi-arrow-up-right-square text-white mr-1" width="1.5em" height="1.5em"  viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path fill-rule="evenodd" d="M10.5 5h-4a.5.5 0 0 0 0 1h2.793l-4.147 4.146a.5.5 0 0 0 .708.708L10 6.707V9.5a.5.5 0 0 0 1 0v-4a.5.5 0 0 0-.5-.5z"/>
                                        </svg> Recorrido
                                    </button>
                                </div>
                            :
                                null
                        }
                    </div>
                    {
                       (permissions & 256) !== 0 ?
                            <div className='row justify-content-center'>
                                <div className='col-6'>
                                <RouteList
                                level={level}
                                handleClickDetail={handleClickDetail}
                                routes={state.data}/>
                                </div>
                            </div>
                        :
                            null
                    }
                </div>
                {
                    (permissions & 128) !== 0 ?
                        <RouteModalUsers
                            users={stateUsers.data}
                            isOpen={stateUsers.modalIsOpen}
                            onClose={handleCloseModalUsers}
                            handleClickCheck={handleClickCheck}
                            handleSaveUsers={handleSaveUsers}/>
                    :
                        null
                }
            </React.Fragment>
    )
    



}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(Routes);

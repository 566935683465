import React from 'react';

import CustomerFormEdit from '../components/CustomerFormEdit'

class CustomerEdit extends React.Component {

    render (){
        return(
            <React.Fragment>
                <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <CustomerFormEdit customer={this.props.location.state.detail}  history={this.props.history}   />
                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    };
}
export default CustomerEdit;
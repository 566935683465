import React,{useState,useMemo} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAddressCard,faSearch } from "@fortawesome/free-solid-svg-icons";
import ActivityListItem from './ActivityListItem'


const  ActivityList = (props) =>{
    const {level,puedeEditar,puedeEliminar,puedeEnviar,activities,handleClickDelete,handleClickUsers,handleClickEdit} = props;

    const [filteredActivities,setFilteredActivities]=useState(activities);
    const [query,setQuery]=useState('');
    useMemo(
        () =>{
          const result = activities.filter(item => {
            return `${item.clientName} ${item.name}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredActivities(result)
    }, [ activities, query ]);


    if (filteredActivities.length === 0) {
        return(
            <div>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label>
                                <FontAwesomeIcon icon={faSearch}  className='text-dark mr-1'/>
                                    Buscar
                            </label>
                            <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <h3>Actividades no encontradas.</h3>
            </div>
        )
    }

    return (
        <div>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label>
                            <FontAwesomeIcon icon={faSearch}  className='text-dark mr-1'/>
                            Buscar
                        </label>
                         <input 
                            className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                        />
                    </div>
                </div>
            </div>
            <ul className="list-unstyled">
            {filteredActivities.map((item,index) => {
                return (
                    <li key={item.activityId}>
                        <ActivityListItem
                            level={level}
                            activity={item} 
                            index={index}
                            handleClickUsers={handleClickUsers}
                            handleClickDelete={handleClickDelete}
                            handleClickEdit={handleClickEdit}
                            puedeEditar={puedeEditar}
                            puedeEliminar={puedeEliminar}
                            puedeEnviar={puedeEnviar}
                        />
                    </li>
                );
            })}
            </ul>
        </div>

         
    )
}
export default ActivityList;
import React from 'react'

import NavMenu from './NavMenu'

function Layout(props){
    return(
        <React.Fragment>
            <NavMenu/>
            {props.children}
        </React.Fragment>
    ) 
}
export default Layout;
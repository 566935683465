import React from 'react';
import ReportActivityGListItem from './ReportActivityGListItem';

const ReportActivityGList = (props) => {
    const {reportList, dates, apikey} = props;
    if (reportList.length === 0) {
        return (
          <div>
            <h3>No hay datos para el reporte de Actividades.</h3>
          </div>
        );
    }
    return (
        <table className="table table-bordered" style={{width:"60%", marginLeft:"auto", marginRight:"auto"}}>
            <col style={{width:"80%"}} />
            <col style={{width:"20%"}} />
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Actividad</th>
                    <th scope="col">Respuestas</th>
                </tr>
            </thead>
            <tbody>
                {reportList.map((item, index) => {
                    return (
                        <ReportActivityGListItem
                            data={item}
                            index={index}
                            dates={dates}
                            apikey={apikey}
                        />
                    )
                })}
            </tbody>
        </table>
    )
}
export default ReportActivityGList;
import React from 'react';

const  ReportRouteGListItem = (props) => {

    const {data}=props;
 
    return(
        <tr>
            <td>{data.userName}</td>
            <td>{data.number}</td>
            <td>{data.petitions}</td>
            {
                "average" in data && (
                    <td>{data.average}</td>
                )
            }
        </tr>
    )
    
}
export default ReportRouteGListItem;
import React from 'react';

const  ReportUserListItem = (props) => {

    const {data}=props;
 
    return(
        <tr>
            <td>{data.userName}</td>
            <td>{data.clientName}</td>
            <td>{data.activationDate}</td>
            <td>{data.permissions}</td>
            <td>{data.status}</td>
            <td>{data.deactivationDate}</td>

            
            
        </tr>
    )
    
}
export default ReportUserListItem;
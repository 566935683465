import React from 'react';



class UserRouteListItem extends React.Component {

    render(){
        return(
            <div className={this.props.className} data-id={this.props.index} onClick={this.props.onClick} >
                <div className="card-header ">
                    <div className='row'>
                        <div className='col-11 text-light'>
                            <strong>{this.props.usr.nombre}</strong> 
                            <br/>
                            {this.props.usr.cliente }
                        </div>
                    </div>

                   
                </div>
               
            </div>
        );
    }

}
export default UserRouteListItem;
import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {loginRequest,logoutRequest} from '../actions'
import {URL_BASE_WS} from '../Constanst'
import MiniLoader from '../components/MiniLoader'
import  logo from '../images/login_web.png'
import  qrLogin from '../images/qr_login.jpeg'
import {preguntarPermisos} from '../FirebaseConfig'

import qs from 'querystring'


import './styles/Login.css'


const Login = (props) => {
    const [state,setState] = useState({
        loading :false,
        error: null
    });

    const [form,setValues] = useState({
        username :''
    });

    const handleInput = event =>{
        setValues({
            ...form,
            [event.target.name] : event.target.value,
        })
    };
    useEffect( () => {
        props.logoutRequest([]);
        
        
    },[]);
    
    const handleSubmit = async event =>{
        event.preventDefault();

        setState({loading:true,error:null});

        const token = await preguntarPermisos();
        console.log(token);

        if (token != null) {
            form["token"] = token;
        }

        console.log(form);

        await fetch(`${URL_BASE_WS}/login`, {
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body:  qs.stringify(form),
        }).then(function(response) {
            setState({loading:false,error:null})
            return response.json();
        }).then(function(data) {
            console.log(data);
            if(data.error){
                setState({loading:false,error:data.message})
            } else {
                setState({loading:false,error:null})
                props.loginRequest(data.userInfo);
                props.history.push('/home');

                ;
            }
        });
    };

    return(
        <div className="login-dark mt-5">
            <form onSubmit={handleSubmit}>
            <img src={logo} className="img-fluid mb-4" alt="CONDEP" />  
                <div className="form-group">
                    <label>Usuario</label>
                    <input 
                    type="user" 
                    name='username' 
                    className="form-control" 
                    minLength='4'
                    placeholder="Usuario" 
                    onChange={handleInput}
                    />
                </div>

                <div className="form-group">
                    <label>Contraseña</label>
                    <input 
                    type="password" 
                    name='password' 
                    className="form-control" 
                    minLength='4'
                    placeholder="Contraseña" 
                    onChange={handleInput}
                    />
                </div>
                {
                    state.loading?
                    <div className="d-flex justify-content-center"><MiniLoader/></div>:
                    <button type="submit" className="btn btn-primary btn-block" >Ingresar</button>
                }
                
                {
                    state.error && (
                        <h6 className="text-danger" >{state.error}</h6>
                    )
                }


                <div class="d-flex justify-content-center">
                    <img src={qrLogin} className=' img-thumbnail mt-4 ' alt='RQ CONDEP' />    
                </div>
            
            </form>
            

        </div>
         
    )
}

const mapDispatchToProps = {
    loginRequest,
    logoutRequest,

};
export default  connect(null,mapDispatchToProps)(Login);
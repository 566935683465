import React from 'react';

const  ReportSchedulesListItem = (props) => {

    const {data}=props;
 
    return(
        <tr>
        
            <td>{data.dateTime}</td>
            <td>{data.userName}</td>
            <td>{data.scheduleType}</td>
            
            
            
            
        </tr>
    )
    
}
export default ReportSchedulesListItem;
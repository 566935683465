import React,{useState, useEffect,Fragment} from 'react';
import {connect} from 'react-redux';
import { useForm } from "react-hook-form";
import {URL_BASE_WS} from '../Constanst'
import MiniLoader from './MiniLoader'
import swal from 'sweetalert';
import qs from 'querystring'

const   PasswordChangeForm = (props) => {
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const { register, handleSubmit, errors,watch } = useForm();


    const [state,setState] = useState({
        loading :false
    });
   

    useEffect( () => {
        if(hasUser===false){
            props.history.replace('/');
        }
    });

   
    const handleChangePassword = async data =>{
        data["userId"]=user.userId;
        setState({loading:true})
        try {
            let response = await fetch(`${URL_BASE_WS}/users/password`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(data),
            });
            response = await response.json();
            console.log(response);
            setState({loading:false})
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            }  else {
                props.history.push('/home');

            }
        } catch(error){
            setState({loading:false})
            swal("Algo salio mal!", `${error.message}`, "error")
        }
    };
    

    
    return(
             <div>
                <h1>Cambio de Contraseña</h1>
                <form>
                    <fieldset></fieldset>
                <div className='container'>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Nueva Contraseña</label>
                                <input 
                                ref={register({ required: true })}
                                onChange={props.onChange}
                                className='form-control' 
                                type='password' 
                                name='password' 
                                />
                                {errors.password && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Confirmar Contraseña</label>
                                <input 
                                ref={register({ required: true,
                                    validate: (value) => {
                                        return value === watch('password'); // value is from password2 and watch will return value from password1
                                    }
                                 })}
                                className='form-control' 
                                type='password' 
                                name='passwordConfirm' 
                                />
                                {errors.passwordConfirm && ( <span className='text-danger'>'Contraseñas deben ser iguales</span>)}  
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'>
                        {
                        state.loading?
                            <div className="d-flex justify-content-center"><MiniLoader/></div>:
                            <button type='button' 
                            onClick={handleSubmit(handleChangePassword)}
                            className="btn btn-primary mt-4">
                                Guardar
                            </button>
                        }

                            
                        </div>
                    </div>
                </div>



                

                    






                    
                </form>
            </div>
        

    );
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(PasswordChangeForm);

import React from 'react';
import {Modal, Button } from 'react-bootstrap';

function ChangePasswordModal(props) {
  return (

    

    <Modal show={props.isOpen} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
              props.error && (
                <span className='text-danger'>{props.error}</span>
              )
            }
          <div className='row'>
                          <div className='col-12'>
                              <div className='form-group'>
                                  <label>* Nueva Contraseña</label>
                                  <input 
                                  onChange={props.onChange}
                                  className='form-control' 
                                  type='password' 
                                  name='password' 
                                  />
                              </div>
                          </div>
            </div>
            <div className='row'>
                          <div className='col-12'>
                              <div className='form-group'>
                                  <label>* Confirmar Contraseña</label>
                                  <input 
                                  onChange={props.onChange}
                                  className='form-control' 
                                  type='password' 
                                  name='passwordConfirm' 
                                  />
                              </div>
                          </div>
            </div>
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleChangePassword}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default ChangePasswordModal;
import React from 'react';
import {Modal, Button } from 'react-bootstrap';
import InputTag from './InputTag'

function FormModalText(props) {
  let {data,tags,removeTag,inputKeyDown,tagInput,error,addTag,handleClickCheck}=props;
  console.log(data)
  console.log(data.data)
  let texto= '' ;
  if(data!==undefined){
    texto= 't' ;
  }
  let imgData=""
  if(data.data!==undefined && data.data!==null ){ 
    imgData= data.data[0];
  }
  
  return (

    

    <Modal show={props.isOpen} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Entrada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
              props.error && (
                <span className='text-danger'>{props.error}</span>
              )
            }
          <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                                  <label>* Texto</label>
                                  <textarea 
                                  onChange={props.onChangeFormModal}
                                  className='form-control' 
                                  type='text' 
                                  value={data.text}
                                  name='text'
                                  rows="3"
                                  maxLength="2000"
                                  />
                              </div>
                          </div>
            </div>

            {data.entryType !== 0 && (
            <div className='row'>
                          <div className='col-12'>
                          <div className='form-group'>
                          <label>* Requerido</label><br/>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" onChange={props.onChangeFormModal} 
                                checked={Number(data.req) === 1} 
                                 type="radio" name="req" id="inlineRadio1" value="1"
                                 />
                                <label class="form-check-label" for="inlineRadio1">SI</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input"  
                                onChange={props.onChangeFormModal} 
                                checked={Number(data.req) !== 1} 
                                type="radio" name="req" id="inlineRadio2" value="0"
                                />
                                <label class="form-check-label" for="inlineRadio2">NO</label>
                            </div>
                        </div>







                          </div>
            </div>
            )}
            {
              data.entryType === 2 && (
                <div className='row'> 
              <div className='col-12'>
                
                          <label>* Opciones</label><br/>
                          <InputTag  
                          removeTag={removeTag}
                          inputKeyDown={inputKeyDown}
                            tagInput={tagInput}
                            addTag={addTag}
                            tags={tags}
                           />
                
              

              </div>

              </div>

              )
            }
            {
              data.entryType === 6 && (
                <div className='row'> 
              <div className='col-12'>
                  <label>* Cargar desde</label><br/>
                  <div class="form-check">
                    <input class="form-check-input" 
                    checked={imgData==='1'}
                      onChange={props.onChangeFormModal} type="radio" name="data" id="imgsource1" value="1" />
                    <label class="form-check-label" for="imgsource1">
                      Camara
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" 
                    checked={imgData==='2'}
                    onChange={props.onChangeFormModal} type="radio" name="data" id="imgsource2" value="2" />
                    <label class="form-check-label" for="imgsource2">
                      Galeria
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" 
                    checked={imgData==='3'}
                    onChange={props.onChangeFormModal} type="radio" name="data" id="imgsource3" value="3" />
                    <label class="form-check-label" for="imgsource3">
                      Ambas
                    </label>
                  </div>
                          
                
              

              </div>

              </div>

              )
            }
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleSaveText}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default FormModalText;
import React from 'react'
import {Modal, Button } from 'react-bootstrap';


const FormModalUsers = (props) =>{
    const {users,handleSaveUsers,handleClickCheck} =props;
    return (

<Modal show={props.isOpen} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
              props.error && (
                <span className='text-danger'>{props.error}</span>
              )
            }
            <div className='row'>
                <div className='col-12'>
                    <ul className="list-unstyled">
                        {users.map((item,index) => {
                            return (
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" checked={item.allowed}  onChange={handleClickCheck} className="custom-control-input" value={index} id={`chk_${item.userId}`} />
                                    <label className="custom-control-label" for={`chk_${item.userId}`}>{item.name}</label>
                                </div>
                            );
                        })}
                    </ul>
                
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleSaveUsers}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
         

    )
}
export default FormModalUsers;
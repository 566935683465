import React from 'react'
import FormFinishListItem from './FormFinishListItem'


const FormList = (props) => {
    const {forms,onDeleteItem,onEditItem,user} = props;

    if (forms.length === 0) {
        return (
            <div>
                <h3>Actividad no tiene información.</h3>
            </div>
        )
    }

    return (
        <div>
        
        
        {forms.map((page, pageIndex) => {
            return(
                <div className="card mt-2">
                    <div className="card-header">Página</div>
                    <div className="card-body">
                    <ul className="list-unstyled mt-2">
                    {page.map((item,index) => {
                        return (
                        <li >
                            <FormFinishListItem 
                            page = {pageIndex}
                            item = {item}
                            index={index}
                            onEditItem={onEditItem}
                            onDeleteItem={onDeleteItem}
                            apikey={user.apikey}
                            />
                        </li>
                        );
                    })}
                    </ul>
                    </div>
                </div>
            );
        })}
        </div>

    )

}
export default FormList;
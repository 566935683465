import React from 'react';

import ReportListItem from './ReportListItem';

class ReportList extends React.Component {
    render(){
        if (this.props.reportList.length === 0) {
            return (
              <div>
                <h3>No hay datos para el reporte.</h3>
              </div>
            );
        }
        return(
            <table className="table">
                <thead className="thead-light">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">{this.props.title}</th>
                    <th scope="col">{this.props.subtitle}</th>
                    <th scope="col">{this.props.description}</th>
                    <th scope="col">{this.props.description2}</th>
                    </tr>
                </thead>
                <tbody>
                {this.props.reportList.map((item, index) => {
                    return (
                    
                        <ReportListItem data={item} index={index}  />
                    
                    );
                })}


                </tbody>
            </table>
        )
    }

}
export default ReportList;
import React from 'react';

import ReportRouteEListItem from './ReportRouteEListItem';

const  ReportRouteEList = (props) => {
    const {reportList} = props;
    
        
        if (reportList.length === 0) {
            return (
              <div>
                <h3>No hay datos para el reporte de Recorridos Específico.</h3>
              </div>
            );
        }


        return(
            <table className="table table-bordered">
            <thead className="thead-dark">
                    <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Duración </th>
                    
                    
                    </tr>
                </thead>
                <tbody>
                {reportList.map((item, index) => {
                    return (
                    
                        <ReportRouteEListItem 
                        data={item}
                         index={index}  />
                    
                    );
                })}


                </tbody>
            </table>
        )
    

}
export default ReportRouteEList;
import React ,{useState,useMemo} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

import PanicListItem from './PanicListItem';

const  PanicList = (props) => {
    const {level,panics,handleClickCheck,handleClickLocation,puedeAtender} = props;

    const [filteredPanics,setFilteredPanics]=useState(panics);
    const [query,setQuery]=useState('');
    

    useMemo(
        () =>{
          const result = panics.filter(item => {
            return `${item.userName} ${item.clientName} ${item.date}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredPanics(result)
    }, [ panics, query ]);

    
        if (filteredPanics.length === 0) {
            return (
              <div>
              <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <FontAwesomeIcon icon={faSearch}  className='mr-2'/>
                                    Buscar
                                </label>
                                <input 
                                className='form-control ml-2' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                <h3>Alertas de Botón de Pánico Activo no encontradas.</h3>
              </div>
            );
        }

        return(
           <div>
           <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <FontAwesomeIcon icon={faSearch}  className='mr-2'/>
                                    Buscar
                                </label>
                                <input 
                                className='form-control ' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                <ul className="list-unstyled">
                {filteredPanics.map((item,index) => {
                    return (
                    <li key={item.id}>
                        <PanicListItem
                            level={level}
                            panic={item} 
                            index={index}
                            puedeAtender={puedeAtender}
                            handleClickLocation={handleClickLocation}
                            handleClickCheck={handleClickCheck}
                        />
                    </li>
                    );
                })}
                </ul>
            </div>
        );
    
}
export default PanicList;
import React,{useState,useMemo} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch } from "@fortawesome/free-solid-svg-icons";
import ActivityFinishListItem from './ActivityFinishListItem'
import FiltroXFecha from './FiltroXFecha';

const  ActivityList = (props) =>{
    const {level,activities,handleClickDetail,handleClickPDF,apikey,loading,handleSyncFecha,handleStarDate,handleEndDate,startDate,endDate,handleClickLocation} = props;
    const [filteredActivities,setFilteredActivities]=useState(activities);
    const [query,setQuery]=useState('');
    useMemo(
        () =>{
          const result = activities.filter(item => {
            return `${item.responseUserName} ${item.responseDateTime} ${item.name}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredActivities(result)
    }, [ activities, query ]);


    if (filteredActivities.length === 0) {
        return(
            <div>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label>
                                <FontAwesomeIcon icon={faSearch}  className='text-dark mr-1'/>
                                    Buscar
                            </label>
                            <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                            <FiltroXFecha 
                            loading={loading}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={ endDate}
                             />
                            </div>
                        </div>
                    </div>
                <h3>Actividades Finalizadas no encontradas.</h3>
            </div>
        )
    }

    return (
        <div>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label>
                            <FontAwesomeIcon icon={faSearch}  className='text-dark mr-1'/>
                            Buscar
                        </label>
                         <input 
                            className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                        />
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                            <FiltroXFecha 
                            loading={loading}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={ endDate}
                             />
                            </div>
                        </div>
                    </div>
            <ul className="list-unstyled">
            {filteredActivities.map((item,index) => {
                return (
                    <li key={item.responseId}>
                        <ActivityFinishListItem
                            level={level}
                            activity={item} 
                            index={index}
                            handleClickDetail={handleClickDetail}
                            handleClickLocation={handleClickLocation}
                            handleClickPDF={handleClickPDF}
                            apikey={apikey}
                        />
                    </li>
                );
            })}
            </ul>
        </div>
    )
}
export default ActivityList;
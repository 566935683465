import React, { Fragment } from 'react';
import {URL_BASE_WS} from '../Constanst'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLandmark,faClock,faListAlt,faFilePdf,faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";

const ActivityListItem = (props) => {
    const {level,activity,handleClickDetail,apikey,index,handleClickLocation}=props;
    const carBg = activity.status === 1 ? 'text-white bg-secondary': activity.status === 99 ? 'text-white bg-danger' : '';
    const iconColor = activity.status === 0 ? 'text-primary' : 'text-white';
    return (
        <div  className={`card   border-secondary mb-3 ${carBg}`}  >
            <div className="card-header ">
                <div className='row'>
                    <div className='col-9 '>
                           <strong className='ml-1'>{activity.responseUserName}</strong>                         
                    </div>
                    <div className='col-3  justify-content-end'>
                        <FontAwesomeIcon icon={faMapMarkedAlt} onClick={handleClickLocation} data-index={index} className={'pointer ' + iconColor +  ' mr-3'}/>
                        <FontAwesomeIcon icon={faListAlt} onClick={handleClickDetail} data-index={index} className={'pointer ' + iconColor +  ' mr-3'}/>
                        <a
                            target='_blank'
                            rel="noopener noreferrer"
                            href={`${URL_BASE_WS}/activities/documents?activityResponseId=${activity.responseId}&apikey=${apikey}`}
                            className='card-text '>
                            <FontAwesomeIcon icon={faFilePdf} className={'pointer ' + iconColor}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                {
                    activity.clientName && (level===0) && (
                        <Fragment>
                            <FontAwesomeIcon icon={faLandmark} /> <span className='card-text ml-2'>{activity.clientName}</span><br/>
                        </Fragment>
                    )
                }
                {
                    activity.responseDateTime && (
                        <Fragment>
                            <FontAwesomeIcon icon={faClock} /> <span className='card-text ml-2'>{activity.responseDateTime}</span><br/>
                        </Fragment>
                    )
                }
                {
                    activity.name && (
                        <Fragment>
                            <FontAwesomeIcon icon={faListAlt} /> <span className='card-text ml-2'>{activity.name}</span>
                        </Fragment>
                    )
                }
            </div>
        </div>
    )

}
export default  ActivityListItem;
import React ,{useState,useMemo} from 'react';
import  DeleteClientModal from '../components/DeleteClientModal';

import CustomerListItem from './CustomerListItem';
import CustomerInactiveModal from './CustomerInactiveModal';
import CustomerActiveModal from './CustomerActiveModal';


const   CustomerList = (props) => {
    const  {handleClickEdit,onDeleteClient,
        handleClickInactive,modalIsOpenInactive,handleOpenModalInactive,handleCloseModalInactive,
        handleClickActive,modalIsOpenActive,handleOpenModalActive,handleCloseModalActive,
        modalIsOpen,handleOpenModal,handleCloseModal,
        customers} = props;

    const [query,setQuery]=useState('');
    const [filteredCustomers,setFilteredCustomers]=useState(customers);

    useMemo(
        () =>{
          const result = customers.filter(item => {
            return `${item.name} ${item.partner} ${item.contact}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredCustomers(result)
    }, [ customers, query ]);

    

    if (filteredCustomers.length === 0) {
        return (
          <div>
          <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
            <h3>Clientes no encontrados.</h3>
          </div>
        );
    }
    

    return(    
           <div>

                <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>


                <ul className="list-unstyled">
                {filteredCustomers.map((item,index) => {
                    return (
                    <li key={item.clientId}>
                        <CustomerListItem 
                        customer={item} 
                        index={index}
                        clientId={item.clientId}
                        onOpenModal={handleOpenModal}
                        handleClickEdit={handleClickEdit}
                        
                        handleOpenModalInactive={handleOpenModalInactive} 
                        handleOpenModalActive={handleOpenModalActive} 
                        />
                    </li>
                    );
                })}
                </ul>


                <CustomerActiveModal 
                            isOpen={modalIsOpenActive} 
                            onClose={handleCloseModalActive}
                            handleClickActive={handleClickActive} 
                            
                             />

                <CustomerInactiveModal 
                            isOpen={modalIsOpenInactive} 
                            onClose={handleCloseModalInactive}
                            handleClickInactive={handleClickInactive} 
                            
                             />

                <DeleteClientModal 
                            isOpen={modalIsOpen} 
                            onClose={handleCloseModal}
                            onDeleteClient={onDeleteClient} 
                            
                             />
            </div>
        
        );
}
export default CustomerList;
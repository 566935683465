import React ,{useState,useMemo} from 'react';
import  DeleteUserModal from '../components/DeleteUserModal';

import UserListItem from './UserListItem';

const   UserList = (props) => {
    

    const  {handleClickEdit,onDeleteUser,modalIsOpen,handleOpenModal,handleCloseModal,puedeEliminar,puedeEditar,level,users} = props;

    const [query,setQuery]=useState('');
    const [filteredUsers,setFilteredUsers]=useState(users);

    useMemo(
        () =>{
          const result = users.filter(item => {
            return `${item.clientName} ${item.username} ${item.name}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredUsers(result)
    }, [ users, query ]);

    

    
        if (users.length === 0) {
            return (
              <div>
              <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                <h3>Usuarios no encontrados.</h3>
              </div>
            );
        }
        return(
           <div>

           <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>




                <ul className="list-unstyled">
                {filteredUsers.map((item,index) => {
                    return (
                    <li key={item.userId}>
                        <UserListItem 
                        usr={item} 
                        level={level}
                        index={index}
                        onOpenModal={handleOpenModal}
                        handleClickEdit={handleClickEdit} 
                        puedeEditar = {puedeEditar}
                        puedeEliminar = {puedeEliminar}
                        />
                    </li>
                    );
                })}
                </ul>

                <DeleteUserModal 
                            isOpen={modalIsOpen} 
                            onClose={handleCloseModal}
                            onDeleteUser={onDeleteUser} 
                             />
            </div>
        );
    
}
export default UserList;
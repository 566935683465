import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import qs from 'querystring'
import PageLoading from '../components/PageLoading';
import swal from 'sweetalert';
import CustomerList from '../components/CustomerList'
import { Link } from 'react-router-dom';



const Customers = (props) => {

    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const [stateModal,setStateModal] = useState({
        modalIsOpen:false,
        
    });
    const [stateModalActive,setStateModalActive] = useState({
        modalIsOpen:false,
        
    });
    const [stateModalInactive,setStateModalInactive] = useState({
        modalIsOpen:false,
        
    });
    const [state,setState] = useState({
            loading: false,
            data: [],
    });
    
    async function fetchData() {
        console.log('fetchData');
        

        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/clients`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        setState({...state,loading: false})
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                swal("Algo salio mal!", `${response.message}`, "error")
                .then((value) => {
                    props.history.replace('/');
                })                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
        } else {
            setState({...state,data:response.clients})
        }
    }

    useEffect( () => {
        console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchData();
    },[]);

    const handleClickEdit = e => {
        let index  = e.currentTarget.dataset.index;
        let clientId  = e.currentTarget.dataset.id;
        let elementIndex  = state.data.find( (item) => Number(item.clientId) === Number(clientId));
        props.history.push({
            pathname: `/customer/${clientId}/edit`,
            state: { detail : elementIndex }
          })
      };
      

    const handleOpenModal = e => {
        console.log("handleOpenModal");
        let index  = e.currentTarget.dataset.id;
        setStateModal({ modalIsOpen: true,deleteIndex:index});
    };
    
    const handleCloseModal = e => {
        setStateModal({ modalIsOpen: false,deleteIndex:0 });
    };

    const handleDeleteClient = async e => {
        let formDelete = {
            clientId:stateModal.deleteIndex
        };
        setStateModal({ modalIsOpen: false,deleteIndex:0 });
        let response =  await fetch(`${URL_BASE_WS}/clients`, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': user.apikey
            },
            body:  qs.stringify(formDelete),
        });
        response = await response.json();
        if(response.error === true){
            swal("Algo salio mal!", `${response.message}`, "error")
        } else {
            fetchData();
        }
    };

    const handleOpenModalActive = e => {
        console.log("handleOpenModal");
        let index  = e.currentTarget.dataset.id;
        setStateModalActive({ modalIsOpen: true,activeIndex:index});
    };
    
    const handleCloseModalActive = e => {
        setStateModalActive({ modalIsOpen: false,activeIndex:0 });
    };

    const handleClickActive = async e => {
        
        let formActive = {
            clientId:stateModalActive.activeIndex
        };
        setStateModalActive({ modalIsOpen: false,activeIndex:0 });
        let response =  await fetch(`${URL_BASE_WS}/clients/enable`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': user.apikey
            },
            body:  qs.stringify(formActive),
        });
        response = await response.json();
        if(response.error === true){
            swal("Algo salio mal!", `${response.message}`, "error")
        } else {
            fetchData();
        }

    }

    const handleOpenModalInactive = e => {
        console.log("handleOpenModal");
        let index  = e.currentTarget.dataset.id;
        setStateModalInactive({ modalIsOpen: true,inactiveIndex:index});
    };
    
    const handleCloseModalInactive = e => {
        setStateModalInactive({ modalIsOpen: false,inactiveIndex:0 });
    };

    const handleClickInactive = async e => {
        
        let formInactive = {
            clientId:stateModalInactive.inactiveIndex
        };
        setStateModalInactive({ modalIsOpen: false,inactiveIndex:0 });
        let response =  await fetch(`${URL_BASE_WS}/clients/disable`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': user.apikey
            },
            body:  qs.stringify(formInactive),
        });
        response = await response.json();
        if(response.error === true){
            swal("Algo salio mal!", `${response.message}`, "error")
        } else {
            fetchData();
        }

    }

     if (state.loading === true) {
        return <PageLoading />;
      }

    return(
            <React.Fragment>
                <div className='container'>
                    <div className='row mb-3 mt-4 justify-content-start'>
                        <div className='col-4'>
                            <h2>Clientes</h2>
                        </div>
                        <div className='col-4 '></div>
                        <div className='col-4 '>
                            <Link to='/customer/new' className='btn btn-primary btn-sm'>
                            <svg className="bi bi-person-plus-fill text-white mr-1" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                <path fillRule="evenodd" d="M13 7.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"/>
                            </svg>
                                 Cliente
                            </Link>
                        </div>
                    </div>
                    
                        <div className='row justify-content-center'>
                            <div className='col-6'>
                                
                               <CustomerList 
                               customers={state.data}
                               onDeleteClient={handleDeleteClient}
                               modalIsOpen={stateModal.modalIsOpen}                               
                               handleOpenModal={handleOpenModal}
                               handleCloseModal={handleCloseModal}
                               
                               modalIsOpenInactive={stateModalInactive.modalIsOpen}
                               handleOpenModalInactive={handleOpenModalInactive}
                               handleCloseModalInactive={handleCloseModalInactive}

                               modalIsOpenActive={stateModalActive.modalIsOpen}
                               handleOpenModalActive={handleOpenModalActive}
                               handleCloseModalActive={handleCloseModalActive}

                               handleClickEdit={handleClickEdit}
                               handleClickInactive={handleClickInactive}
                               handleClickActive={handleClickActive}
                               />
                               
                               
                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(Customers);

import React from 'react';
import {Modal,Button } from 'react-bootstrap';

function CustomerInactiveModal(props) {
  return (

    

    <Modal show={props.isOpen} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Estas seguro ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Estas por <strong>INACTIVAR</strong> un Cliente.</p>
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={props.handleClickInactive}>
            Inactivar
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default CustomerInactiveModal;
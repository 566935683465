import React from 'react';

import UserFormEdit from '../components/UserFormEdit'

class UserEdit extends React.Component {

    render (){
        return(
            <React.Fragment>
                <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <UserFormEdit  userEdit={this.props.location.state.detail}  history={this.props.history}   />
                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    };
}
export default UserEdit;
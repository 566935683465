import React from 'react'

import ReportList from './ReportList'

class Report extends React.Component {
    render(){
        return (
            <div className='container'>
                <div>
                    <h1>{this.props.titleReport}</h1>
                </div>

             
                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <div className="input-group flex-nowrap">
                            <input type="text" className="form-control" placeholder="Fecha Inicio" aria-label="Username" aria-describedby="addon-wrapping" />
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping"> - </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Fecha Fin" aria-label="Username" aria-describedby="addon-wrapping" />
                            <div class="input-group-prepend">
                                <button class="btn btn-primary"  type="button" id="button-addon1">
                                <svg className="bi bi-search text-white mr-1" width="1.3em" height="1.3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-end mt-2 mb-2'>
                    <div className='col-3'>
                        <button class="btn btn-info"  type="button" id="button-addon1">
                        <svg class="bi bi-card-list text-white mr-1" width="1.3em" height="1.3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path fillRule="evenodd" d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
                            <circle cx="3.5" cy="5.5" r=".5"/>
                            <circle cx="3.5" cy="8" r=".5"/>
                            <circle cx="3.5" cy="10.5" r=".5"/>
                        </svg>
                            Exportar Excel
                            </button>
                    </div>
                </div>




                

                <div className='row justify-content-center mt-4'>
                    <div className='col'>
                        <ReportList 
                        reportList={this.props.reportList}  
                        title={this.props.title}
                        subtitle={this.props.subtitle}
                        description={this.props.description}
                        descripcion2={this.props.descripcion2}
                        />
                    </div>

                </div>
                
                

            </div>
        )
    }
}
export default Report;
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark,faClock,faUserCheck, faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {URL_BASE_WS} from '../Constanst'

const SchedulesListItem  = (props) => {
    
    const  {level,apikey,handleClickLocation,index} = props;

    return(
           <div className="card   border-secondary mb-3 ">
                <div className="card-header ">
                    <div className='row'>
                        <div className='col-10 '>
                           <strong className='ml-1'>{props.schedules.userName}</strong> 
                        </div>
                        <div className='col-2  justify-content-end'>
                            <FontAwesomeIcon icon={faMapMarkedAlt}  
                                onClick={handleClickLocation}
                                data-index={index}
                                className='pointer text-primary ml-3'/>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-2'>
                            <img src={`${URL_BASE_WS}/schedules/photos?photoId=${props.schedules.photoId}&apikey=${apikey}`} data-id={props.schedules.photoId} alt='foto' onClick={props.handleClickImage} className='img-fluid'  />
                        </div>
                        <div className='col-10'>
                            {
                                props.schedules.clientName && (props.level ===0) && (
                                    <Fragment>
                                        <FontAwesomeIcon icon={faLandmark}  /> <span className='card-text ml-2'>{props.schedules.clientName}</span><br/>
                                    </Fragment>
                                )
                            }
                            {
                                props.schedules.scheduleType && (
                                    <Fragment>
                                        <FontAwesomeIcon icon={faUserCheck}  /> <span className='card-text ml-2'>{props.schedules.scheduleType}</span><br/>
                                    </Fragment>
                                )
                            }
                            {
                                props.schedules.dateTime && (
                                    <Fragment>
                                        <FontAwesomeIcon icon={faClock}  /> <span className='card-text ml-2'>{props.schedules.dateTime }</span><br/>
                                    </Fragment>
                                )
                            }
                        </div>

                    </div>


                    
                    

                    </div>
               
            </div>
        
    )

}
export default SchedulesListItem;
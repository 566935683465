import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './components/Layout'
import Customers from './pages/Customers'
import CustomerNew from './pages/CustomerNew'
import CustomerEdit from './pages/CustomerEdit'
import UserNew from './pages/UserNew'
import UserEdit from './pages/UserEdit'

import Routes from './pages/Routes'
import RoutesFinish from './pages/RoutesFinish'
import RouteNew from './pages/RouteNew'
import RouteMap from './pages/RouteDetail'

import RouteHistory from './pages/RouteHistory'
import PanicHistory from './pages/PanicHistory'
import PanicActive from './pages/PanicActive'
import FormNew from './pages/FormNew'
import FormDetailFinish from './pages/FormDetailFinish'

import Forms from './pages/Forms'
import FormsFinish from './pages/FormsFinish'
import FormEdit from './pages/FormEdit'

import PanicReport from './pages/PanicReport'
import RouteReport from './pages/RouteReport'
import RouteEReport from './pages/RouteEReport'
import SchedulesReport from './pages/SchedulesReport'
import ActivityReport from './pages/ActivityReport'


import UserReport from './pages/UserReport'
import CustomerReport from './pages/CustomerReport'

import Schedules from './pages/Schedules'
import PasswordChange from './pages/PasswordChange'

import Login from './pages/Login'




import Users from './pages/Users'
import NotFound from './pages/NotFound'
import Home from './pages/home';
import { Form } from 'react-bootstrap';


 function App(){
     return (
         <BrowserRouter>
            <Layout>
                <Switch>
                <Route exact path='/' component={Login} />
                    <Route exact path='/customer/new' component={CustomerNew} />
                    
                    <Route exact path='/customer/:clientId/edit' component={CustomerEdit} />
                    
                    
                    <Route exact path='/home' component={Home} />
                    <Route exact path='/customers' component={Customers} />
                    
                    
                    
                    <Route exact path='/schedules' component={Schedules} />
                    <Route exact path='/users' component={Users} />
                    <Route exact path='/user/new' component={UserNew} />
                    <Route exact path='/user/edit' component={UserEdit} />



                    <Route exact path='/routes' component={Routes} />
                    <Route exact path='/routes-finish' component={RoutesFinish} />
                    <Route exact path='/route/new' component={RouteNew} />
                    <Route exact path='/route/map' component={RouteMap} />
                    
                    <Route exact path='/route-report' component={RouteHistory} />

                    <Route exact path='/form/new' component={FormNew} />
                    <Route exact path='/form/edit' component={FormEdit} />
                    <Route exact path='/form/detail' component={FormDetailFinish} />
                    
                    <Route exact path='/forms' component={Forms} />
                    <Route exact path='/forms-finish' component={FormsFinish} />
                    

                    
                    
                    
                    <Route exact path='/panic-active' component={PanicActive} />
                    <Route exact path='/panic-history' component={PanicHistory} />


                    <Route exact path='/report-customer' component={CustomerReport} />
                    <Route exact path='/report-user' component={UserReport} />

                    <Route exact path='/report-schedules' component={SchedulesReport} />

                    

                    
                    <Route exact path='/report-route' component={RouteReport} />
                    <Route exact path='/report-routee' component={RouteEReport} />

                    <Route exact path='/report-panic' component={PanicReport} />
                    <Route exact path='/report-activity' component={ActivityReport} />

                    <Route exact path='/changePassword' component={PasswordChange} />


                    

                    

                    
                    <Route  component={NotFound} />
                </Switch>
            </Layout>
         </BrowserRouter>
     );
 }
 export default App;
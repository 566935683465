import React, { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLandmark,faCheckSquare,faClock,faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";



const  PanicListItem = (props) => {

    const {level,panic,index,handleClickCheck,handleClickLocation,puedeAtender}=props;
    // const carBg= panic.status===1?' text-white bg-secondary':'';
    

    return (
        // <div  className={`card   border-secondary mb-3 ${carBg}`}  >
        <div  className="card   border-secondary mb-3">
            <div className="card-header ">
                <div className='row'>
                    <div className='col-10'>
                           <strong className='ml-1'>{panic.userName}</strong>                         
                    </div>
                    <div className='col-2  justify-content-end'>
                    {
                        panic.status===0 && (
                                <FontAwesomeIcon icon={faMapMarkedAlt}  
                                    onClick={handleClickLocation} 
                                    data-index={index} 
                                    className='pointer text-primary mr-3'/>
                        )
                    }
                    {
                        panic.status===0 && puedeAtender===true &&  (

                                <FontAwesomeIcon icon={faCheckSquare}  
                                    onClick={handleClickCheck} 
                                    data-index={index} 
                                    className='pointer text-primary'/>
                            
                        )
                    }
                    </div>
                    
                </div>
            </div>
            <div className='card-body'>
                {
                    panic.clientName && (level===0) && (  
                        <Fragment>
                            <FontAwesomeIcon icon={faLandmark}  /> <span className='card-text ml-2'>{panic.clientName }</span><br/>
                        </Fragment>
                    )
                }
                {
                    panic.date && (  
                        <Fragment>
                            <FontAwesomeIcon icon={faClock}  /> <span className='card-text ml-2'>{panic.date }</span><br/>
                        </Fragment>
                    )
                }
                        
            </div>
        </div>
    )

    

}
export default PanicListItem;
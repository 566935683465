import React,{useState,useRef} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const  InputTag = (props) =>  {
   
    let {tags,removeTag,inputKeyDown,tagInput,addTag}=props;

  
      
  
      return (
        <div className="input-tag">
          <ul className="input-tag__tags">
            { tags.map((tag, i) => (
              <li key={tag}>
                <div className='row'>
                  <div className='col-10'>
                    {tag}
                  </div>
                  <div className='col-2'>
                    <FontAwesomeIcon icon={faTimes} className='ml-2 pointer text-danger' onClick={() => { removeTag(i); }}/>
                  </div>
                </div>                
              </li>
            ))}
            <li className="input-tag__tags__input">
              <div className='row'>  
                <div className='col-10'>
                <input type="text" className="form-control" onKeyDown={inputKeyDown} ref={ tagInput } />
                </div>
                <div className='col-2'>
                <FontAwesomeIcon className=' pointer text-primary' onClick={addTag} icon={faPlus} />
                </div>

              </div>
             
              
               
              
            </li>
          </ul>
        </div>
      );
  }
  export default InputTag;
import React from 'react';

import ReportRouteGListItem from './ReportRouteGListItem';

const  ReportRouteGList = (props) => {
    const {reportList} = props;
    
        
        if (reportList.length === 0) {
            return (
              <div>
                <h3>No hay datos para el reporte de Recorridos General.</h3>
              </div>
            );
        }


        return(
            <table className="table table-bordered">
            <thead className="thead-dark">
                    <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Recorridos Realizados</th>
                    <th scope="col">Recorridos Solicitados</th>
                    <th scope="col">Duración Promedio</th>
                    </tr>
                </thead>
                <tbody>
                {reportList.map((item, index) => {
                    return (
                        <ReportRouteGListItem
                            data={item}
                            index={index}/>
                    );
                })}
                </tbody>
            </table>
        )
    

}
export default ReportRouteGList;
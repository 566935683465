import React from 'react';

const  ReportCustomerListItem = (props) => {

    const {data}=props;
 
    return(
        <tr>
            <td>{data.name}</td>
            <td>{data.maxUsers}</td>
            <td>{data.activeUsers}</td>
            <td>{data.availableUsers}</td>
            <td>{data.activationDate}</td>
            <td>{data.status}</td>

            
            
        </tr>
    )
    
}
export default ReportCustomerListItem;
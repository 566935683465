import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import PageLoading from '../components/PageLoading';
import swal from 'sweetalert';
import Swal2 from 'sweetalert2'
import PanicList from '../components/PanicList'
import qs from 'querystring'
import ModalMapa from '../components/ModalMapa';

const  PanicActive = (props) => {
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const level = user.level;
    const puedeAtender = user.level<2 ? true :   (user.permissions & 1024) !== 0 ? true : false;

    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    }); 

   
    async function fetchAlerts() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/alerts/active`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                
                    props.history.replace('/');
                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.alerts})
        }
    }

    const handleClickLocation = (e) => {
        e.preventDefault()
        let index  = Number(e.currentTarget.dataset.index);
        console.log('handleClickLocation:'+index);
        console.log('handleClickLocation:'+state.data[index].latitude);
        
        setModal({
            isOpen:true,
            latitude: state.data[index].latitude,
            longitude: state.data[index].longitude,
            fecha:state.data[index].date,
            titulo:state.data[index].userName
            
        })
    }
    
    const handleClickCheck = async (e) => { 
        let index  = Number(e.currentTarget.dataset.index);
        console.log(index)
        let alertId = state.data[index].alertId
        console.log(state.data[index])
        let htmlObservations = "<div style=\"font-size: medium; text-align: left; max-height: 250px; overflow: auto\">"
        if (state.data[index].observations != null) {
            state.data[index].observations.map((observation, index) => {
                htmlObservations += "<a><b>" + observation.userName + " (" + observation.dateTime + ")</b></a><br>" + "<pre>" + observation.observation + "</pre>";
            });
        }
        htmlObservations += "</div>"
        await  Swal2.fire({
            title: "Atender Alerta",
            html: "<h4>Observaciones:</h4>" + htmlObservations,
            input: 'textarea',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            denyButtonText: "Finalizar",
            cancelButtonText: "Cancelar",
            denyButtonColor: "#4CAF50",
            returnInputValueOnDeny: true,
            preConfirm: (value) => {
                if (value) {
                    return value;
                } else {
                    Swal2.showValidationMessage('Falta Observación') 
                }
            },
            preDeny: (value) => {
                if (value) {
                    return value;
                } else {
                    Swal2.showValidationMessage('Falta Observación')
                    return false
                }
            }
            }).then((result) => {
            console.log(result)
                if(result.isConfirmed===true){
                    (async () => {
                        let formJson={
                            attendedObservation: result.value,
                            alertId:alertId
                        }
                        console.log(formJson);
                        let response =  await fetch(`${URL_BASE_WS}/alerts/active`, {
                            method: 'put',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                                'Authorization': user.apikey
                            },
                            body:  qs.stringify(formJson),
                        });
                        response = await  response.json();
                        console.log(response);
                        if(response.error === true){
                            swal("Algo salio mal!", `${response.message}`, "error")
                        } else {
                            fetchAlerts();
                        }
                    })();
                } else if (result.isDenied===true) {
                    (async () => {
                        let formJson={
                            attendedObservation: result.value,
                            alertId:alertId
                        }
                        console.log(formJson);
                        let response =  await fetch(`${URL_BASE_WS}/alerts/active`, {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                                'Authorization': user.apikey
                            },
                            body:  qs.stringify(formJson),
                        });
                        response = await  response.json();
                        console.log(response);
                        if(response.error === true){
                            swal("Algo salio mal!", `${response.message}`, "error")
                        } else {
                            fetchAlerts();
                        }
                    })();
                }
            });
    }
/************MOdal******** */
    const [modal,setModal] = useState({
        isOpen: false,
        longitude:0,
        latitude:0
    })

    const handleCloseModal = e => {
        setModal({ isOpen: false,
            longitude:0,
            latitude:0 });
    };


    useEffect( () => {
        console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchAlerts();
    },[]);

    if (state.loading === true) {
        return <PageLoading />;
      }
    
    return(
           <React.Fragment>
                <div className='container'>
                <div className='row mb-3 mt-4 justify-content-start'>
                        <div className='col-6'>
                            <h2>Botón de Pánico Activo</h2>
                        </div>
                        <div className='col-4 '></div>
                        <div className='col-4 '>
                        
                            
                        </div>
                    </div>
                    
                    <div className='row justify-content-center'>
                            <div className='col-6'>
                                <PanicList
                                    level={level}
                                    panics={state.data}
                                    handleClickLocation={handleClickLocation}
                                    handleClickCheck={handleClickCheck}
                                    puedeAtender={puedeAtender}
                                />
                            </div>
                        </div>
                </div>

                <ModalMapa
                longitude={modal.longitude}
                latitude={modal.latitude}
                fecha={modal.fecha}
                titulo={modal.titulo}
                onClose={handleCloseModal}
                isOpen={modal.isOpen}
                />
            </React.Fragment>
    );
    
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(PanicActive);
import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import PageLoading from '../components/PageLoading';
import swal from 'sweetalert';
import  ReportCustmerList from '../components/ReportCustmerList';
import  CustomerSearchForm from '../components/CustomerSearchForm';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel} from "@fortawesome/free-solid-svg-icons";



const  CustomerReport = (props) => {

    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    let refClient = useRef();
    const puedeGenerar = user.level<2 ? true :   (user.permissions & 262144) !== 0 ? true : false;
    const puedeConsultar = user.level<2 ? true :   (user.permissions & 524288) !== 0 ? true : false;

    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    }); 
    
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [params, setParams] = useState('');
    
    async function fetchReportUser() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/reports/clients?${params}`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                
                    props.history.replace('/');
                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.clientsData})
        }
    }

    const handleStarDate = (date) =>{
        if(date > endDate){
            setEndDate(date)
        }
        setStartDate(date);
    }
    const handleEndDate = (date) =>{
        if (date < startDate) {
            setStartDate(date)
        }
        setEndDate(date);
    }

    const handleSearchCustomer =(e) => {
        e.preventDefault()
        
        const valClient = refClient.current.value;

        let query='';
      
        
        if(valClient !== undefined && valClient!== "" ){
            query+='clientName='+valClient+'&'; 
        }
        
        if(startDate !== undefined && endDate!== undefined ){
            if(startDate<endDate ){
                query+=`startDate=${formattedDate(startDate)}&endDate=${formattedDate(endDate)}&`; 
                setEndDate();
                setStartDate();
            } else {
                swal("Algo salio mal!", 'Revisa los valores de fechas', "error")
            }
        }
        setParams(query);

    }

    function formattedDate(d = new Date) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        return `${day}/${month}/${year}`;
    }

    useEffect( () => {
        console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchReportUser();
    },[params]);

    if (state.loading === true) {
        return <PageLoading />;
      }


    
    return(
        <div className='container-fluid'>
            
         
        <CustomerSearchForm 
         className='mb-2 mt-2'
         refClient={refClient}
         startDate={startDate}
         endDate={ endDate}
         puedeConsultar={puedeConsultar}
         handleStarDate={handleStarDate}
         handleEndDate={handleEndDate}
         handleSearchCustomer={handleSearchCustomer}
        />

        {
            puedeGenerar===true && (
                <div className='d-flex justify-content-end'>
            <a className='btn btn-primary text-white mb-1'
            target='_blank' 
                            rel="noopener noreferrer" 
            href={`${URL_BASE_WS}/reports/clients/sheet?${params}&apikey=${user.apikey}`} 
             >
                <FontAwesomeIcon icon={faFileExcel} /> Excel
            </a>

            </div>

            )
        }
           

         
         
        
        <ReportCustmerList 
            reportList={state.data} 
            />


        </div>
            
    )
    

}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(CustomerReport);

import React,{useState,useMemo} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import RouteItemList from './RouteItemList';

const   RouteList = (props) => {
    const {level,routes,handleClickDetail}=props;
    
    const [filteredRoutes,setFilteredRoutes]=useState(routes);
    const [query,setQuery]=useState('');
    

    useMemo(
        () =>{
          const result = routes.filter(item => {
            return `${item.userName} ${item.startDate}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredRoutes(result)
    }, [ routes, query ]);


    
    if (filteredRoutes.length === 0) {
        return (
            <div>
            <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <FontAwesomeIcon icon={faSearch}  className='mr-2'/>
                                    Buscar
                                </label>
                                <input 
                                className='form-control ml-2' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                <h3>Recorridos Activos no encontrados.</h3>
            </div>
        );
    }
    return(
        <div>
        <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <FontAwesomeIcon icon={faSearch} />
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
            <ul className="list-unstyled">
                {filteredRoutes.map( (item,index) => {
                    return (
                    <li key={item.id}>
                        <RouteItemList
                        level={level}
                        route={item} 
                        index={index}
                        handleClickDetail={handleClickDetail}
                        />

                    </li>
                    );
                })}
            </ul>
        </div>
    );
    
}
export default RouteList;
import React,{useState, useEffect,Fragment} from 'react';
import {connect} from 'react-redux';
import { useForm } from "react-hook-form";
import {URL_BASE_WS} from '../Constanst'
import MiniLoader from './MiniLoader'
import swal from 'sweetalert';
import qs from 'querystring'

const   UserForm = (props) => {
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const { register, handleSubmit, errors } = useForm();


    const [state,setState] = useState({
        loading :false
    });
    const [permisosChecked,setPermisosChecked] = useState({
        CHK_1:false,
        CHK_2:false
    });
    const [permisos,setPermisos] = useState({
        CHK_1:0,
        CHK_2:0
    });

    useEffect( () => {
        if(hasUser===false){
            props.history.replace('/');
        }
    });

    const onSubmit =   async data => {
        try {
            setState({loading:true})
            let contador=0;
            let conWeb = true;
            Object.keys(permisos).map((key) => {
                if(key==='CHK_2' && permisos[key] ===0    ){
                    conWeb=false;
                }
                if(conWeb===true){
                    contador = contador+permisos[key];
                }
            });
            
            data['permissions'] = contador;
            console.log(data);
            let response = await fetch(`${URL_BASE_WS}/users`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(data),
            });
            response = await response.json();
            console.log(response);
            setState({loading:false})
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            } else {
                props.history.push('/users');
            }
        } catch(error){
            swal("Algo salio mal!", `${error.message}`, "error")
        }
        

    };

    const toggleCheckbox = (e) => {
        if(e.target.checked){            
            setPermisos({...permisos,
                [e.target.name] : Number(e.target.value)
            })
            setPermisosChecked({...permisosChecked,
                [e.target.name] : true
            })
        } else{
            setPermisos({...permisos,
                [e.target.name] :0
            })
            setPermisosChecked({...permisosChecked,
                [e.target.name] : false
            })
        }
    }

    
    return(
             <div>
                <h1>Usuario</h1>
                <form>
                    <fieldset></fieldset>
                <div className='container'>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Nombre</label>
                                <input 
                                ref={register({ required: true })}
                                onChange={props.onChange}
                                className='form-control' 
                                type='text' 
                                name='name' 
                                />
                                {errors.name && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Usuario</label>
                                <input 
                                ref={register({ required: true })}
                                className='form-control' 
                                type='text' 
                                name='username' 
                                />
                                {errors.username && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Contraseña</label>
                                <input 
                                ref={register({ required: true })}
                                className='form-control' 
                                type='password' 
                                name='password' 
                                />
                                {errors.password && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                    </div>
                    <fieldset><strong>Acceso</strong></fieldset>
                    <div className='row'>
                        <div className='col-2'>
                            <div className="form-check">
                                <input className="form-check-input" onChange={toggleCheckbox} name='CHK_1' value='1' type="checkbox" id='defaultCheck1'  />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    App
                                </label>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className="form-check">
                                <input className="form-check-input" onChange={toggleCheckbox} name='CHK_2' type="checkbox" value="2" id='defaultCheck2' />
                                <label className="form-check-label" htmlFor="defaultCheck2">
                                    Web
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        

                        permisos.CHK_2 === 2 && (
                            <Fragment>
                                <fieldset><strong>Usuarios</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='4' checked={permisosChecked.CHK_3} name='CHK_3' type="checkbox" id='defaultCheck3'  />
                                            <label className="form-check-label" htmlFor="defaultCheck3">
                                                Agregar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} type="checkbox" checked={permisosChecked.CHK_4} name='CHK_4' value="8" id='defaultCheck4' />
                                            <label className="form-check-label" htmlFor="defaultCheck4">
                                            Editar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} name='CHK_5' checked={permisosChecked.CHK_5}  type="checkbox" value="16" id='defaultCheck5' />
                                            <label className="form-check-label" htmlFor="defaultCheck5">
                                            Eliminar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} type="checkbox" checked={permisosChecked.CHK_6} name='CHK_6' value="32" id='defaultCheck6' />
                                            <label className="form-check-label" htmlFor="defaultCheck6">
                                            Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <fieldset><strong>Horarios</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='64' checked={permisosChecked.CHK_7} name='CHK_7' type="checkbox" id='defaultCheck7'  />
                                            <label className="form-check-label" htmlFor="defaultCheck7">
                                                Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Recorridos</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='128' checked={permisosChecked.CHK_8} name='CHK_8' type="checkbox" id='defaultCheck8'  />
                                            <label className="form-check-label" htmlFor="defaultCheck8">
                                            Solicitar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='256' checked={permisosChecked.CHK_9} name='CHK_9' type="checkbox" id='defaultCheck9'  />
                                            <label className="form-check-label" htmlFor="defaultCheck9">
                                            Consultar activos
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='512' checked={permisosChecked.CHK_10} name='CHK_10' type="checkbox" id='defaultCheck10'  />
                                            <label className="form-check-label" htmlFor="defaultCheck10">
                                            Consultar historial
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Botón de Pánico</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='1024' checked={permisosChecked.CHK_11} name='CHK_11' type="checkbox" id='defaultCheck11'  />
                                            <label className="form-check-label" htmlFor="defaultCheck11">
                                            Atender
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='2048' checked={permisosChecked.CHK_12} name='CHK_12' type="checkbox" id='defaultCheck12'  />
                                            <label className="form-check-label" htmlFor="defaultCheck12">
                                            Editar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='4096' checked={permisosChecked.CHK_13} name='CHK_13' type="checkbox" id='defaultCheck13'  />
                                            <label className="form-check-label" htmlFor="defaultCheck13">
                                            Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Actividades</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='8192' checked={permisosChecked.CHK_14} name='CHK_14' type="checkbox" id='defaultCheck14'  />
                                            <label className="form-check-label" htmlFor="defaultCheck14">
                                                Crear
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='16384' checked={permisosChecked.CHK_15} name='CHK_15' type="checkbox" id='defaultCheck15'  />
                                            <label className="form-check-label" htmlFor="defaultCheck15">
                                                Enviar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='32768' checked={permisosChecked.CHK_16} name='CHK_16' type="checkbox" id='defaultCheck16'  />
                                            <label className="form-check-label" htmlFor="defaultCheck16">
                                                Eliminar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='65536' checked={permisosChecked.CHK_17} name='CHK_17' type="checkbox" id='defaultCheck17'  />
                                            <label className="form-check-label" htmlFor="defaultCheck17">
                                                Editar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='131072' checked={permisosChecked.CHK_18} name='CHK_18' type="checkbox" id='defaultCheck18'  />
                                            <label className="form-check-label" htmlFor="defaultCheck18">
                                                Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Reportes</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='262144' checked={permisosChecked.CHK_19} name='CHK_19' type="checkbox" id='defaultCheck19'  />
                                            <label className="form-check-label" htmlFor="defaultCheck19">
                                                Generar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='524288' checked={permisosChecked.CHK_20} name='CHK_20' type="checkbox" id='defaultCheck20'  />
                                            <label className="form-check-label" htmlFor="defaultCheck20">
                                                Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </Fragment>
                           
                       )
                        
                    }
                    <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'>
                        {
                        state.loading?
                            <div className="d-flex justify-content-center"><MiniLoader/></div>:
                            <button type='button' 
                            onClick={handleSubmit(onSubmit)}
                            className="btn btn-primary mt-4">
                                Guardar
                            </button>
                        }

                            
                        </div>
                    </div>
                </div>



                

                    






                    
                </form>
            </div>
        

    );
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(UserForm);

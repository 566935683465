

import React from 'react';

import UserRouteListItem from './UserRouteListItem';

class  UserRouteList extends React.Component {

    render(){
        if (this.props.users.length === 0) {
            return (
              <div>
                <h3>Usuarios no encontrados.</h3>
              </div>
            );
        }
        return(
           <div>
                <ul className="list-unstyled">
                {this.props.users.map((item, index) => {
                    return (
                    <li key={item.id}>
                        <UserRouteListItem usr={item} index={index} className={this.props.className} onClick={this.props.onClick}  />
                    </li>
                    );
                })}
                </ul>
            </div>
        );
    }
}
export default UserRouteList;
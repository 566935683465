import React from 'react'

import  Report from '../components/Report'

class PanicReport extends React.Component {
    constructor(props){
        super(props);
        console.log("1. constructor ");
        this.state = {
            loading: true,
            error: null,
            data: [
                {title:'20/06/2020',subtitle:'21/06/2020',descripcion:'Juan Perez Lopez'},
                {title:'17/06/2020',subtitle:'19/06/2020',descripcion:'Luis Luna Castro'},
                {title:'15/06/2020',subtitle:'16/06/2020',descripcion:'Mario Medina'},
                {title:'12/06/2020',subtitle:'12/06/2020',descripcion:'Manuel Rosales'},
                {title:'10/06/2020',subtitle:'1/06/2020',descripcion:'Antonio Soto'},
                {title:'10/06/2020',subtitle:'12/06/2020',descripcion:'Francisco Salvatierra'}
            ],
            //data: undefined,
          };

    }

    render(){
        return(
            <Report 
            titleReport='Reporte Boton de Panico' 
            reportList={this.state.data} 
            title='Fecha Inicio'
            subtitle='Fecha Fin'
            description='Guardia'
            />
        )
    }

}
export default PanicReport;
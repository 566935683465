import React from 'react';

import ReportUserListItem from './ReportUserListItem';

const  ReportUserList = (props) => {
    const {reportList} = props;
    
        
        if (reportList.length === 0) {
            return (
              <div>
                <h3>No hay datos para el reporte Usuarios.</h3>
              </div>
            );
        }


        return(
            <table className="table table-bordered">
            <thead className="thead-dark">
                    <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Fecha de Activación</th>
                    <th scope="col">Permisos</th>
                    <th scope="col">Estatus</th>
                    <th scope="col">Fecha de Inactivación</th>
                    
                    </tr>
                </thead>
                <tbody>
                {reportList.map((item, index) => {
                    return (
                    
                        <ReportUserListItem 
                        data={item}
                         index={index}  />
                    
                    );
                })}


                </tbody>
            </table>
        )
    

}
export default ReportUserList;
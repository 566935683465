import React, { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAddressCard,faLandmark ,faUserSlash,faUserCheck } from "@fortawesome/free-solid-svg-icons";



const  CustomerListItem = (props) => {

    
    const carBg= props.customer.status===1?' text-white bg-secondary':'';
        return(
            
            <div className={`card   border-secondary mb-3 ${carBg}`} >
                <div className="card-header ">
                    <div className='row'>
                        <div className='col-9 '>
                           <strong className='ml-1'>{props.customer.name}</strong> 
                        </div>
                        <div className='col-3  justify-content-end'>
                            <svg  data-index={props.index} data-id={props.clientId} onClick={props.handleClickEdit}  className="bi bi-pencil-square pointer text-primary mr-3" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>

                        

                
                            
                            
                            
                            <svg   data-id={props.clientId} onClick={props.onOpenModal} className="bi bi-trash pointer text-danger mr-3" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>


                            {
                                props.customer.status===0 && (
                                    
                                    <FontAwesomeIcon icon={faUserSlash} onClick={props.handleOpenModalInactive}  data-id={props.customer.clientId} className='pointer text-info' />
                                )
                            }
                            {
                                props.customer.status===1 && (
                                    <FontAwesomeIcon icon={faUserCheck}  onClick={props.handleOpenModalActive} data-id={props.customer.clientId} className='pointer text-primary' />
                                )
                            }

                            

                            


                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    {
                        
                            props.customer.partner && (
                                <Fragment>
                                    
                                    <FontAwesomeIcon icon={faLandmark}  /> <span className='card-text ml-2'>{props.customer.partner }</span>
                                </Fragment>
                            )

                        }
                        {
                        
                        props.customer.contact && (
                            <Fragment>
                                <br/>
                                <FontAwesomeIcon icon={faAddressCard}  /> <span className='card-text ml-2'>{props.customer.contact }</span>
                            </Fragment>
                        )

                    }

                    </div>
               
            </div>
        );
    

}
export default CustomerListItem;
import React ,{useState,useMemo} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import FiltroXFecha from './FiltroXFecha';
import PanicHistoryListItem from './PanicHistoryListItem';

const  PanicHistoryList = (props) => {
    const {puedeEditar,level,panics,handleClickEdit,handleClickDetail,handleClickLocation,loading,handleSyncFecha,handleStarDate,handleEndDate,startDate,endDate,apikey} = props;

    const [filteredPanics,setFilteredPanics]=useState(panics);
    const [query,setQuery]=useState('');
    

    useMemo(
        () =>{
          const result = panics.filter(item => {
            return `${item.userName} ${item.clientName} ${item.date} ${item.attendedUserName} ${item.attendedDate}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredPanics(result)
    }, [ panics, query ]);

    
        if (filteredPanics.length === 0) {
            return (
              <div>
              <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <FontAwesomeIcon icon={faSearch}  className='mr-2'/>
                                    Buscar
                                </label>
                                <input 
                                className='form-control ml-2' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                            <FiltroXFecha 
                            loading={loading}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={ endDate}
                             />
                            </div>
                        </div>
                    </div>
                <h3>Alertas de Botón de Pánico no encontradas.</h3>
              </div>
            );
        }

        return(
           <div>
           <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <FontAwesomeIcon icon={faSearch}  className='mr-2'/>
                                    Buscar
                                </label>
                                <input 
                                className='form-control ' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                            <FiltroXFecha 
                            loading={loading}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={ endDate}
                             />
                            </div>
                        </div>
                    </div>
                <ul className="list-unstyled">
                {filteredPanics.map((item,index) => {
                    return (
                    <li key={item.id}>
                        <PanicHistoryListItem
                            level={level}
                            panic={item} 
                            puedeEditar={puedeEditar}
                            index={index}
                            handleClickLocation={handleClickLocation}
                            handleClickEdit={handleClickEdit}
                            handleClickDetail={handleClickDetail}
                            apikey={apikey}
                        />
                    </li>
                    );
                })}
                </ul>
            </div>
        );
    
}
export default PanicHistoryList;
import React from 'react'
import FormListItem from './FormListItem'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTrash} from "@fortawesome/free-solid-svg-icons";



const FormList = (props) => {
    const {forms,onDeleteItem,onEditItem,onDragEnd,onDeletePage} = props;
    console.log('d')
    console.log(forms)
    console.log('dd')
    if (forms.length === 0) {
        return (
            <div>
                <h3>Agrega datos a la actividad.</h3>
            </div>
        )
    }
   

    return (
        
        <DragDropContext onDragEnd={onDragEnd}>
        <div>
        
        {forms.map((page, pageIndex) => {
            return(
                <Droppable key={pageIndex} droppableId={`${pageIndex}`}>
                {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    <div className="card mt-2">
                        <div className="card-header">
                            <div className='row'>
                                <div className='col-11'>
                                    Página
                                </div>
                                {pageIndex !== 0 &&(
                                <div className='col-1'>
                                    <FontAwesomeIcon  icon={faTrash} 
                                    //data-page={props.page} 
                                    data-index={pageIndex} 
                                    onClick={onDeletePage}  
                                    className='pointer mr-2 text-danger' />
                                </div>
                                )}
                            </div>
                            
                        </div>
                        <div className="card-body">
                        <ul className="list-unstyled mt-2">
                        {page.map((item,index) => {
                            return (
                            <Draggable key={pageIndex+","+index} draggableId={pageIndex+","+index} index={index}>
                            {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <li >
                                <FormListItem 
                                page = {pageIndex}
                                item = {item}
                                index={index}
                                onEditItem={onEditItem}
                                onDeleteItem={onDeleteItem}
                                />
                            </li>
                            </div>
                            )}
                            </Draggable>
                            );
                        })}
                        </ul>
                        </div>
                    </div>
                    {provided.placeholder}
                </div>
                )}
                </Droppable>
            );
        })}
        
        </div>
        </DragDropContext>
    )

}
export default FormList;
import React ,{useState,useRef} from 'react';
import swal from 'sweetalert';
import Swal2 from 'sweetalert2'
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import MiniLoader from '../components/MiniLoader'
import qs from 'querystring'
import FormAddItem from '../components/FormAddItem'
import FormModalText from '../components/FormModalText';
import FormModalUsers from '../components/FormModalUsers'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave} from "@fortawesome/free-solid-svg-icons";

import FormList from '../components/FormList'



const  FormNew = (props) => {
    const {user} = props;
    const [stateModal,setStateModal] = useState({
        modalIsOpen:false,
        data:null
    });
    
    
    const handleCloseModal = e => {
        setStateModal({ modalIsOpen: false,});
    };

    const [form,setForm] = useState({
        list:[[]]
    })
    
    const handleClickOption = (e) => {
        let idTipo  = Number(e.currentTarget.dataset.id);
        if(idTipo===100){
            // const item = {
            //     index: Object.keys(form.list).length,
            //     entryType:100,
            //     required:0,
            //     text:'',
            //     data:''
            // }
            setForm({
                list: [...form.list, []]
            })


        } else {
            setModalForm({entryType:idTipo})
            setStateModal({ 
                modalIsOpen: true,
                idTipo:idTipo
            });
        }
        
    };

    const [modalForm,setModalForm] = useState({text:'',req:0})
    const handleChangeText = e => {
        setModalForm({
            ...modalForm,
            [e.target.name] : e.target.value
        })
    }

    

    const handleSaveText = e => {
        if(modalForm===undefined){
            console.log('sin form')
        } else {
            if(modalForm.text !== undefined){
                
                
                if(Number(stateModal.idTipo)===2 && stateTags.tags.length===0 ){
                    console.log("Sin Tags")
                    setStateModal({ ...stateModal,error:'Ingresa las opciones'});    

                } else  if(Number(stateModal.idTipo)===6 && modalForm.data === undefined ){
                    console.log("Sin Opcion IMG")
                    setStateModal({ ...stateModal,error:'Ingresa la opcion de carga'});    

                }
                else {
                    const texto = modalForm.text;
                    let req= 0;
                    let data=null

                    if(modalForm.req !== undefined){
                        req= Number(modalForm.req);
                    }
                    if(Number(stateModal.idTipo)===2 ||  modalForm.entryType===2 ){
                        data=stateTags.tags
                    }
                    if(Number(stateModal.idTipo)===6 ||  modalForm.entryType===6 ){
                        if(modalForm.data !== undefined){
                            data=modalForm.data
                        } else{
                            data='1'
                        }
                    }
                    

                    if(modalForm.indexList===undefined){
                        const item = {
                            page: Object.keys(form.list).length - 1,
                            index: Object.keys(form.list[form.list.length - 1]).length,
                            entryType:Number(stateModal.idTipo),
                            required:req,
                            text:texto,
                            data:data
                        }
                        console.log(item)
                        form.list[form.list.length - 1] = [...form.list[form.list.length - 1], item]
                        setForm({
                            list: form.list
                        })
                    } else {
                        const item = {
                            page: modalForm.page,
                            index: modalForm.index,
                            entryType:modalForm.entryType,
                            required:req,
                            text:texto,
                            data:data
                        }
                        setModalForm(
                            form.list[modalForm.page][modalForm.indexList]=item
                        )
                        
                        console.log("editar")
                    }
                    setStateModal({ modalIsOpen: false,});    
                    setModalForm({text:'',req:0})
                    setStateTags({tags: []})
                }
                
            } else {
                setStateModal({ ...stateModal,error:'Ingresa  texto'});    
                console.log('sin text')
            }
        }
    }

      
    const handleDeletePage= (e) => {
        
        let index  = Number(e.currentTarget.dataset.index);
        swal({
            title: "Eliminar",
            text: "Esta por eliminar la Pagina",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                let newList =form.list;
                        newList.splice(index, 1);
                        setForm({
                            list:newList
                        }) 

            } else {
                
            }
        });


    }

    const handleDeleteItem = (e) => {
        let page = Number(e.currentTarget.dataset.page);
        let index  = Number(e.currentTarget.dataset.index);

        swal({
            title: "Eliminar",
            text: "Estas por eliminar Entrada",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                let newList =form.list;
                        newList[page].splice(index, 1);
                        setForm({
                            list:newList
                        })

            } else {
                
            }
        });
    }

    const handleEditItem = (e) => {
        let page = Number(e.currentTarget.dataset.page);
        let index  = Number(e.currentTarget.dataset.index);
        console.log(form.list[page][index])
        let data = form.list[page][index];

        if(data.entryType===2){
            setStateTags({ tags: data.data});
        }
        
        setModalForm({page:page,index:data.index,text:data.text,req:data.required,entryType:data.entryType,data:data.data,indexList:index})
        setStateModal({ 
            modalIsOpen: true,
        });
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
          }
        const sPage = +source.droppableId;
        const dPage = +destination.droppableId;
        console.log(sPage + "," + source.index)
        console.log(dPage + "," + destination.index)
        let newList =form.list;
        const removed = newList[sPage].splice(source.index, 1)
        newList[dPage].splice(destination.index, 0, removed[0])
        setForm({
            list:newList
        })
    }

    const handleSaveForm = async (e) => { 
        await  Swal2.fire({
            title: "Actividad!",
            text: "Nombre de la Actividad:",
            input: 'text',
            showCancelButton: true,
            preConfirm: (value) => {
               if (value) {
                   return value;
                } else {
                  Swal2.showValidationMessage('Falta Titulo')   
                }
              }
        })  .then((result) => {
            console.log(result)
            if(result.isConfirmed===true){
                (async () => {
                    let formJson={
                        name: result.value,
                        //entries:form.list
                    }
                    formJson.entries = []
                    let tempIndex = 0;
                    form.list.map((page) => {
                        let entry = {
                            index: tempIndex,
                            entryType: 100
                        }
                        formJson.entries.push(entry)
                        tempIndex += 1;
                        page.map((item) => {
                            entry = {
                                index: tempIndex,
                                entryType: item.entryType,
                                required: item.required,
                                text: item.text,
                                data: item.data
                            }
                            formJson.entries.push(entry)
                            tempIndex += 1;
                        });
                    });
                    console.log(JSON.stringify(formJson));


        
                    let response =  await fetch(`${URL_BASE_WS}/activities`, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Authorization': user.apikey
                        },
                        
                        body: JSON.stringify(formJson),
                    });
                    response = await  response.json();
                    console.log(response);
                    if(response.error === true){
                        swal("Algo salio mal!", `${response.message}`, "error")
                    } else {
                        fetchUsers(response.activityId);
                        //
                    }
                    
                })();
            }
        });
    }
    


    //************Tags************* */
    let tagInput = useRef();
    const [stateTags,setStateTags] = useState({ 
        txt:'',
        tags: []
    }) 

    const removeTag = (i) => {
      const newTags = [ ...stateTags.tags ];
      newTags.splice(i, 1);
      setStateTags({ tags: newTags });
    }
   const addTag = (e) => {
    const val = tagInput.current.value;
        if (stateTags.tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
            return;
        }
        setStateTags({ tags: [...stateTags.tags, val]});
        tagInput.current.value=null;
      }
    
    const inputKeyDown = (e) => {
      const val = e.target.value;
      if (e.key === 'Enter' && val) {
        if (stateTags.tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
          return;
        }
        setStateTags({ tags: [...stateTags.tags, val]});
        e.target.value=null;
      } else if (e.key === 'Backspace' && !val) {
        removeTag(stateTags.tags.length - 1);
      }
    }
    //************Users************* */
    const [stateUsers,setStateUsers] = useState({ 
        activityId:0,
        modalIsOpen:false,
        data: []
    }) 
    const handleCloseModalUsers = (e) =>{
        setStateUsers({
            activityId:0,
            modalIsOpen:false,
            data: []
        })
        props.history.push('/forms');
    }

    const handleClickCheck = (e) => {
        let dataTemp=stateUsers.data;
        let actTemp= stateUsers.activityId;
        let itemUser=dataTemp[e.target.value];
        if(e.target.checked){
            itemUser.allowed=1;
        } else {
            itemUser.allowed=0;
        }
        dataTemp[e.target.value]=itemUser;
        setStateUsers({
            activityId:actTemp,
            modalIsOpen:true,
            data : dataTemp
        })
    }
    const handleSaveUsers =  async (e) => {
        try {
            let dataTemp=stateUsers.data;
            let dataAllowed = dataTemp.filter(function (item) {
                return item.allowed === 1;
            });
            let usersStr = dataAllowed.reduce((resp, item) => `${resp}${item.userId},` ,'')
            let formUsers = {
                activityId:stateUsers.activityId,
                clientId:user.clientId,
                users:  usersStr.substring(0, usersStr.length - 1)
            }

            let response = await fetch(`${URL_BASE_WS}/activities/users`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(formUsers),
            });
            response = await response.json();
            console.log(response);
            //setState({loading:false})
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            } else {
                props.history.push('/forms');
            }
        } catch(error){
            //setState({loading:false})
            swal("Algo salio mal!", `${error.message}`, "error")
        }

        //console.log(formUsers);


    }

    async function fetchUsers(activityId) {
        
        let response =  await fetch(`${URL_BASE_WS}/activities/users?activityId=${activityId}&clientId=${user.clientId}`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        console.log(response);
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                    props.history.replace('/');
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
        } else {
            setStateUsers({ activityId:activityId,modalIsOpen:true,data:response.users})
        }
    }

    return (
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-3 mt-3'>
                                <FormAddItem handleClick={handleClickOption} />
                        </div>
                        <div className='col-6 mt-3'>
                            <FormList  
                            
                                forms={form.list}
                                onDeleteItem={handleDeleteItem}
                                onEditItem={handleEditItem}
                                onDragEnd={onDragEnd}
                                onDeletePage={handleDeletePage}
                                
                            />
                        </div>
                        <div className='col-2'>
                            <button type='button' onClick={handleSaveForm} className='btn btn-primary mt-4'>
                                <FontAwesomeIcon icon={faSave} /> Guardar
                            </button>

                        </div>
                    </div>
                    <FormModalText
                    isOpen={stateModal.modalIsOpen}                               
                    onClose={handleCloseModal}
                    onChangeFormModal={handleChangeText}
                    handleSaveText={handleSaveText}       
                    data={modalForm}     

                    error={stateModal.error}

                    removeTag={removeTag}
                    inputKeyDown={inputKeyDown}
                    tagInput={tagInput}
                    addTag={addTag}
                    tags={stateTags.tags}

                 />
                 <FormModalUsers
                    users={stateUsers.data}                               
                    isOpen={stateUsers.modalIsOpen}                               
                    onClose={handleCloseModalUsers}
                    handleClickCheck={handleClickCheck}
                    handleSaveUsers={handleSaveUsers}
                    />


                 
                </div>

               
            
        
    )

}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(FormNew);


import React, { Fragment } from 'react';
import {URL_BASE_WS} from '../Constanst'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLandmark,faPencilAlt,faClock, faUserCheck, faHistory,faMapMarkedAlt,faListAlt, faFilePdf} from "@fortawesome/free-solid-svg-icons";



const  PanicHistoryListItem = (props) => {

    const {level,puedeEditar,panic,index,handleClickEdit,handleClickDetail,handleClickLocation,apikey}=props;
    // const carBg= panic.status===1?' text-white bg-secondary':'';
    

    return (
        // <div  className={`card   border-secondary mb-3 ${carBg}`}  >
        <div  className="card   border-secondary mb-3">
            <div className="card-header ">
                <div className='row'>
                    <div className='col-9 '>
                           <strong className='ml-1'>{panic.userName}</strong>                         
                    </div>
                    <div className='col-3  justify-content-end'>
                    {
                        panic.status!==0 && (
                                <FontAwesomeIcon icon={faMapMarkedAlt}  
                                    onClick={handleClickLocation} 
                                    data-index={index} 
                                    className='pointer text-primary mr-3'/>
                        )
                    }
                    {
                        panic.status!==0 && puedeEditar===true ?
                        <FontAwesomeIcon icon={faPencilAlt}
                            onClick={handleClickEdit}
                            data-index={index}
                            className='pointer text-primary mr-3'/>
                        :
                        <FontAwesomeIcon icon={faListAlt}
                            onClick={handleClickDetail}
                            data-index={index}
                            className='pointer text-primary mr-3'/>
                    }
                        <a target='_blank' rel="noopener noreferrer" href={`${URL_BASE_WS}/alerts/documents?alertId=${panic.alertId}&apikey=${apikey}`} className='card-text'>
                            <FontAwesomeIcon icon={faFilePdf}   className={'pointer text-primary'}/>
                        </a>
                    </div>
                    
                </div>
            </div>
            <div className='card-body'>
                {
                    panic.clientName && (level===0) && (  
                        <Fragment>
                            <FontAwesomeIcon icon={faLandmark}  /> <span className='card-text ml-2'>{panic.clientName }</span><br/>
                        </Fragment>
                    )
                }
                {
                    panic.date && (  
                        <Fragment>
                            <FontAwesomeIcon icon={faClock}  /> <span className='card-text ml-2'>{panic.date }</span><br/>
                        </Fragment>
                    )
                }
                {
                    panic.attendedUserName && (  
                        <Fragment>
                            <FontAwesomeIcon icon={faUserCheck}  /> <span className='card-text ml-2'>{panic.attendedUserName }</span><br/>
                        </Fragment>
                    )
                }
                {
                    panic.attendedDate && (  
                        <Fragment>
                            <FontAwesomeIcon icon={faHistory}  /> <span className='card-text ml-2'>{panic.attendedDate }</span><br/>
                        </Fragment>
                    )
                }
                        
            </div>
        </div>
    )

    

}
export default PanicHistoryListItem;
import React,{useEffect,useRef, Fragment} from 'react'
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOsm from 'ol/source/OSM';
import OlFeature from 'ol/Feature';

import OlOverlay from "ol/Overlay";
import { fromLonLat,toLonLat ,transform} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OLPoint from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/LineString';


import {Fill, Stroke,Style, Text,Icon} from 'ol/style'
import './styles/RouteMap.css';
import {toStringHDMS} from 'ol/coordinate';


const RouteMap = (props) => {
    const {detail} = props;
    const { innerWidth: width, innerHeight: height } = window;
    const pointInit= detail.locations[0];
    
    console.log(pointInit)

    var rasterLayer = new OlLayerTile({
        name: 'OSM',
        source: new OlSourceOsm()
    }); 

    var container = useRef(null);
    var content = useRef(null);
    var closer = useRef(null);
    //var container = React.findDOMNode(refs.popup)
    //var content = document.getElementById('popup-content');
    //var closer = document.getElementById('popup-closer');

    /**
     * Create an overlay to anchor the popup to the map.
     */
    
   

    
    
   // console.log('I')
    //console.log(overlay)

    const map = new OlMap({
        layers: [rasterLayer],
       // overlays: [overlay],
        view: new OlView({
          center: fromLonLat([pointInit.longitude, pointInit.latitude]),
          zoom: 16
        })
    });

    

    const addMarkers = () => {
        let iconFeatures=[];
        let pointFeatures=[];
     
        detail.locations.map((item,index) => {
            var webMercator = fromLonLat([item.longitude, item.latitude])
            pointFeatures.push(transform([item.longitude,item.latitude],'EPSG:4326', 'EPSG:3857'))
            var iconFeature = new OlFeature({
                geometry: new OLPoint(webMercator),
                name: `${item.date}`,
                population: 4000,
                rainfall: 500
            });
            var iconStyle = [
                new Style({
                    image: new Icon(/** @type {module:ol/style/Icon~Options} */({
                            anchor: [0.5, 1],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            src: require('../images/map_marker.png')
                    }))
                }),
                new Style({
                    text: new Text({
                        text: `${index+1}`,
                        offsetY: -7,
                        scale: 1.2,
                        fill: new Fill({
                            color: "#fff"
                        }),
                        stroke: new Stroke({
                            color: "0",
                            width: 3
                          })
                    })
                })
            ];

            iconFeature.setStyle(iconStyle);
           

            iconFeatures.push(iconFeature)

        });


        
       

        var vectorSource = new VectorSource({
            features: iconFeatures
        });

        var vectorLayer = new VectorLayer({
            source: vectorSource
        });

        map.addLayer(vectorLayer);

       var layerLines = new VectorLayer({
           source: new VectorSource({
               features: [new OlFeature({
                   geometry: new MultiLineString(pointFeatures),
                   name: 'Line',
                   
               })]
           }),
           style : new Style({
            stroke : new Stroke({
              color : 'red',
              width: 2
            })
          })
       });
     
       map.addLayer(layerLines);
       
    }

    var overlay=null

    const handleClickCloser = (e)=>{
        e.preventDefault()
        overlay.setPosition(undefined);
        closer.current.blur();
        return false;
    }


    useEffect( () => {
        map.setTarget('divMap');
        
         overlay = new OlOverlay({
            element: container.current,
            autoPan: true,     
            autoPanAnimation: {
                duration: 250,
            },
        });

        map.addOverlay(overlay)


        addMarkers();

        map.on('singleclick', function (evt) {
            var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
              });
              if (feature) {
                console.log(feature.values_.name);
                var coordinate = evt.coordinate;
                var hdms = toStringHDMS(toLonLat(coordinate));
                overlay.setPosition(coordinate);
                content.current.innerHTML =feature.values_.name;
              }
            

           
           
          });
        
    },[]);
    

    return(
        <Fragment>
            <div id="divMap" style={{ width: `100%`, height: `${height}px` }}>
            </div>
            <div id="popup" className="ol-popup"  ref = {container}>
                <a href="#" id="popup-closer" ref={closer} onClick={handleClickCloser} className="ol-popup-closer"></a>
                <div id="popup-content" ref={content}></div>
            </div>
            
            
        </Fragment>
        
        
    )

}
export default RouteMap;
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLandmark,faPencilAlt,faTrashAlt,faUserPlus} from "@fortawesome/free-solid-svg-icons";

const ActivityListItem = (props) => {
    const {level,puedeEditar,puedeEliminar,puedeEnviar,activity,handleClickDelete,handleClickUsers,handleClickEdit,index}=props;
    const carBg= activity.status===1?' text-white bg-secondary':'';
    return (
        <div  className={`card   border-secondary mb-3 ${carBg}`}  >
            <div className="card-header ">
                <div className='row'>
                    <div className='col-9 '>
                           <strong className='ml-1'>{activity.name}</strong>                         
                    </div>
                    <div className='col-3  justify-content-end'>
                        {
                            puedeEditar===true && (
                                <FontAwesomeIcon icon={faPencilAlt}  onClick={handleClickEdit} data-index={index} className='pointer text-primary mr-3'/>
                            )
                        }
                        
                        {
                            puedeEnviar===true && (
                                <FontAwesomeIcon icon={faUserPlus}  onClick={handleClickUsers} data-index={index} className='pointer text-primary mr-3'/>
                            )
                        }

                        {
                            puedeEliminar===true && (
                                <FontAwesomeIcon icon={faTrashAlt} onClick={handleClickDelete} data-id={activity.activityId}   className='pointer text-danger'/>
                            )
                        }
                        
                        
                        
                    </div>
                </div>
            </div>
            {
                activity.clientName && (level===0) && (
                    <div className='card-body'>
                            <Fragment>
                                <FontAwesomeIcon icon={faLandmark}  /> <span className='card-text ml-2'>{activity.clientName }</span>
                            </Fragment>
                    </div>
                )
            }
        </div>
    )

}
export default  ActivityListItem;
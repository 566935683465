import React from 'react';

import PasswordChangeForm from '../components/PasswordChangeForm'

class PasswordChange extends React.Component {

    render (){
        return(
            <React.Fragment>
                <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <PasswordChangeForm history={this.props.history}/>
                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    };
}
export default PasswordChange;
import React from 'react';
import {Modal } from 'react-bootstrap';

function ShedulesImageModal(props) {
  return (

    

    <Modal show={props.isOpen} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
              props.url && (
                <img src={props.url} alt='foto'  className='img-fluid'  />
              )
            }
          
            
        </Modal.Body>
      </Modal>
  );
}

export default ShedulesImageModal;
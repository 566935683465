import React ,{useState,useMemo} from 'react';
import {URL_BASE_WS} from '../Constanst'
import SchedulesListItem from './SchedulesListItem';
import FiltroXFecha from './FiltroXFecha';
import ShedulesImageModal from './ShedulesImageModal';


const   SchedulesList = (props) => {
    
    const  {level,schedules,apikey,loading,handleSyncFecha,handleStarDate,handleEndDate,startDate,endDate,handleClickLocation} = props;

    const [query,setQuery]=useState('');
    const [filteredSchedules,setFilteredSchedules]=useState(schedules);
    
    const [state,setState] = useState({
        urlImage:''
    });
    const [stateModal,setStateModal] = useState({
        modalIsOpen :false
    });

    const handleClickImage = e => {
        let photoId  = e.currentTarget.dataset.id;
        const url=`${URL_BASE_WS}/schedules/photos?photoId=${photoId}&apikey=${apikey}`
        console.log(url);
        setState({ urlImage: url});
        setStateModal({ modalIsOpen: true });
    };
    
    const handleCloseModal = e => {
        setStateModal({ modalIsOpen: false });
    };

    

    useMemo(
        () =>{
          const result = schedules.filter(item => {
            return `${item.userName} ${item.dateTime} ${item.scheduleType}`.toLowerCase().includes(query.toLowerCase());
      })
      setFilteredSchedules(result)
    }, [ schedules, query ]);

    

    
        if (filteredSchedules.length === 0) {
            return (
              <div>
              <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                            <FiltroXFecha 
                            loading={loading}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={ endDate}
                             />
                            </div>
                        </div>
                    </div>
                <h3>Horarios no encontrados.</h3>
              </div>
            );
        }
        return(
           <div>

           

                
           <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>
                                    <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    Buscar
                                </label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                value={query}
                                onChange={(e) =>{
                                    setQuery(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>

                    
                            <FiltroXFecha 
                            loading={loading}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={ endDate}
                             />
                            



                    


                <ul className="list-unstyled mt-2">
                {filteredSchedules.map((item,index) => {
                    return (
                    <li key={item.scheduleId}>
                        <SchedulesListItem
                        level={level}
                        schedules={item} 
                        handleClickImage = {handleClickImage}
                        apikey={apikey}
                        handleClickLocation={handleClickLocation}
                        index={index}
                        />
                    </li>
                    );
                })}
                </ul>

                <ShedulesImageModal
                    isOpen={stateModal.modalIsOpen}
                    onClose={handleCloseModal}
                    url = {state.urlImage}
                 />

               
            </div>
        );
    
}
export default SchedulesList;
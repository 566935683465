import React from 'react';

class ReportListItem extends React.Component {
    render(){
        return(
        <tr>
            <th scope="row">{this.props.index+1}</th>
            <td>{this.props.data.title}</td>
            <td>{this.props.data.subtitle}</td>
            <td>{this.props.data.descripcion}</td>
            <td>{this.props.data.description2}</td>
            
        </tr>
        )
    }
}
export default ReportListItem;
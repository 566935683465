import React from 'react';

import UserForm from '../components/UserForm'

class UserNew extends React.Component {

    render (){
        return(
            <React.Fragment>
                <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <UserForm history={this.props.history}/>
                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    };
}
export default UserNew;
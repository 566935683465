import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import {logoutRequest} from '../actions'
import {Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt,faKey  } from "@fortawesome/free-solid-svg-icons";
import  brand from '../images/header.png'
import qs from 'querystring'

const NavMenu  = (props) =>  {
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const level = Number(user.level);
    const permissions = Number(user.permissions);
    const mostrarClientes = level === 0;
    const mostrarUsuarios = level === 0 || level === 1 || (permissions & 4) !== 0 || (permissions & 8) !== 0 || (permissions & 16) !== 0 || (permissions & 32) !== 0;
    const mostrarHorarios = level === 0 || level === 1 || (permissions & 64) !== 0;
    const mostrarRecorridoActivo = level === 0 || level === 1 || (permissions & 128) !== 0  || (permissions & 256);
    const mostrarRecorridoHistorial = level === 0 || level === 1 || (permissions & 512);
    const mostrarPanicoActivo = level === 0 || level === 1 || (permissions & 1024) !== 0;
    const mostrarPanicoHistorial = level === 0 || level === 1 || (permissions & 2048) !== 0 || (permissions & 4096) !== 0;
    const mostrarActividades = level === 0 || level === 1 || (permissions & 8192) !== 0 || (permissions & 16384) !== 0 || (permissions & 32768) !== 0 || (permissions & 65536) !== 0;
    const mostrarActividadesFinalizadas = level === 0 || level === 1 || (permissions & 131072) !== 0;
    const mostrarReporteCliente = level === 0;
    const mostrarReportes = level === 0 || level === 1 || (permissions & 262144) !== 0 || (permissions & 524288) !== 0;

    const handleLogOut = async (e) => {
        e.preventDefault();
        console.log(user.apikey);
        await fetch(`${URL_BASE_WS}/logout`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': user.apikey
            },
            body:  qs.stringify('{}'),
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            props.logoutRequest([]);
            window.location.pathname = '/'

        });
    };
    
    if(hasUser ===false ){
        return(
            null
        );
    }
        return (
            <Navbar bg="secondary" variant="dark" expand="lg" sticky="top">
                <NavLink to='/home'>
                    <Navbar.Brand >
                        <img src={brand}  alt="CONDEP Brand" loading="lazy" />
                    </Navbar.Brand>
                </NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {
                            mostrarClientes || mostrarUsuarios ?
                            <NavDropdown title="Configuración" id="dropdown-conf">
                                {
                                    mostrarClientes ?
                                    <NavLink to='/customers'>
                                        <NavDropdown.Item href="#customers">Cliente</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                                {
                                    mostrarUsuarios && mostrarClientes ?
                                    <NavDropdown.Divider />
                                    : null
                                }
                                {
                                    mostrarUsuarios ?
                                    <NavLink to='/users'>
                                        <NavDropdown.Item href="#user">Usuario</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                            </NavDropdown>
                            : null
                        }
                        {
                            mostrarHorarios ?
                            <NavLink to='/schedules'>
                                <Nav.Link href="#schedules">Horarios</Nav.Link>
                            </NavLink>
                            : null
                        }
                        {
                            mostrarRecorridoActivo || mostrarRecorridoHistorial ?
                            <NavDropdown title="Recorrido" id="basic-nav-dropdown">
                                {
                                    mostrarRecorridoActivo ?
                                    <NavLink to='/routes'>
                                        <NavDropdown.Item href="#routes">Recorridos Activos</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                                {
                                    mostrarRecorridoActivo && mostrarRecorridoHistorial ?
                                    <NavDropdown.Divider />
                                    : null
                                }
                                {
                                    mostrarRecorridoHistorial ?
                                    <NavLink to='/routes-finish' >
                                            <NavDropdown.Item href="#routes-finish">Recorridos Finalizados</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                            </NavDropdown>
                            : null
                        }
                        {
                            mostrarPanicoActivo || mostrarPanicoHistorial ?
                            <NavDropdown title="Pánico" id="basic-nav-dropdown">
                                {
                                    mostrarPanicoActivo ?
                                    <NavLink to='/panic-active'>
                                        <NavDropdown.Item href="#panic-active">Botón de Pánico Activo</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                                {
                                    mostrarPanicoActivo && mostrarPanicoHistorial ?
                                    <NavDropdown.Divider />
                                    : null
                                }
                                {
                                    mostrarPanicoHistorial ?
                                    <NavLink to='/panic-history'>
                                        <NavDropdown.Item href="#panic-history">Historial Botón de Pánico</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                            </NavDropdown>
                            : null
                        }
                        {
                            mostrarActividades || mostrarActividadesFinalizadas ?
                            <NavDropdown title="Actividades" id="basic-nav-dropdown">
                                {
                                    mostrarActividades ?
                                    <NavLink to='/forms'>
                                        <NavDropdown.Item href="#/form-fix">Actividades</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                                {
                                    mostrarActividades && mostrarActividadesFinalizadas ?
                                    <NavDropdown.Divider />
                                    : null
                                }
                                {
                                    mostrarActividadesFinalizadas ?
                                    <NavLink to='/forms-finish'>
                                        <NavDropdown.Item href="#forms-finish">Actividades Finalizadas</NavDropdown.Item>
                                    </NavLink>
                                    : null
                                }
                            </NavDropdown>
                            : null
                        }
                        {
                            mostrarReportes ?
                            <NavDropdown title="Reportes" id="basic-nav-dropdown">
                                {
                                    mostrarReporteCliente ?
                                    [
                                        <NavLink to='/report-customer'>
                                            <NavDropdown.Item href="#/report-customer">Clientes</NavDropdown.Item>
                                        </NavLink>,
                                        <NavDropdown.Divider />
                                    ]
                                    : null
                                }
                                <NavLink to='/report-user'>
                                    <NavDropdown.Item href="#/report-user">Usuarios</NavDropdown.Item>
                                </NavLink>
                                <NavDropdown.Divider />
                                <NavLink to='/report-schedules'>
                                    <NavDropdown.Item href="#/report-schedules">Horarios</NavDropdown.Item>
                                </NavLink>
                                <NavDropdown.Divider />
                                <NavLink to='/report-route'>
                                    <NavDropdown.Item href="#report-route">Recorridos General</NavDropdown.Item>
                                </NavLink>
                                <NavDropdown.Divider />
                                <NavLink to='/report-routee'>
                                    <NavDropdown.Item href="#report-routee">Recorridos Especifico</NavDropdown.Item>
                                </NavLink>
                                <NavDropdown.Divider />
                                <NavLink to='/report-activity'>
                                    <NavDropdown.Item href="#report-activity">Actividades</NavDropdown.Item>
                                </NavLink>
                            </NavDropdown>
                            : null
                        }
                    </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end">
                    <Nav className="mr-4">
                    <NavDropdown alignRight title={user.username} className='text-white' id="basic-nav-dropdown">
                        <NavLink to='/changePassword'>
                            <NavDropdown.Item href="#/changePassword"> Cambiar Contraseña</NavDropdown.Item>
                        </NavLink> 
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#logout" onClick={handleLogOut}>  Cerrar Sesion</NavDropdown.Item>
                    </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );

    
}
const mapDispatchToProps = {
    logoutRequest,

};

const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(NavMenu);
import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import qs from 'querystring'
import PageLoading from '../components/PageLoading';
import swal from 'sweetalert';

import UserList from '../components/UserList'
import { Link } from 'react-router-dom';

const  Users = (props) => {
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const puedeEliminar = user.level<2 ? true :   (user.permissions & 16) !== 0 ? true : false;
    const puedeEditar = user.level<2 ? true :   (user.permissions & 8) !== 0 ? true : false;
    const level = user.level;

    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    });
    const [stateModal,setStateModal] = useState({
        modalIsOpen:false,
        
    });
    async function fetchUsers() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/users`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                //swal("Algo salio mal!", `${response.message}`, "error")
                //.then((value) => {
                    props.history.replace('/');
                //})                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.users})
        }
    }

    const handleOpenModal = e => {
        console.log("handleOpenModal");
        let index  = e.currentTarget.dataset.id;
        setStateModal({ modalIsOpen: true,deleteIndex:index});
    };
    
    const handleCloseModal = e => {
        setStateModal({ modalIsOpen: false,deleteIndex:0 });
    };

    const handleClickEdit = e => {
        let userId  = e.currentTarget.dataset.id;
        let index  = state.data.find( (item) => Number(item.userId) === Number(userId));
        props.history.push({
            pathname: `/user/edit`,
            state: { detail: index }
          })
    };

    const handleDeleteUser = async e => {
        let formDelete = {
            userId:stateModal.deleteIndex
        };
        setStateModal({ modalIsOpen: false,deleteIndex:0 });
        let response =  await fetch(`${URL_BASE_WS}/users`, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': user.apikey
            },
            body:  qs.stringify(formDelete),
        });
        response = await response.json();
        if(response.error === true){
            swal("Algo salio mal!", `${response.message}`, "error")
        } else {
            fetchUsers();
        }
    };

    useEffect( () => {
        console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchUsers();
    },[]);

    if (state.loading === true) {
        return <PageLoading />;
      }


    return (
           <React.Fragment>
                <div className='container'>
                    <div className='row mb-3 mt-4 justify-content-start'>
                        <div className='col-4'>
                            <h2>Usuarios</h2>
                        </div>
                        <div className='col-4 '></div>
                        <div className='col-4 '>
                        
                        {
                            user.level < 2 ||  (user.permissions & 4) !== 0?
                            <Link to='/user/new' className='btn btn-primary btn-sm'>
                            <svg className="bi bi-person-plus-fill text-white mr-1" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                <path fillRule="evenodd" d="M13 7.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"/>
                            </svg>
                                 Usuario
                            </Link>: null
                        }
                            


                        </div>
                    </div>
                    
                    <div className='row justify-content-center'>
                            <div className='col-6'>
                                <UserList  
                                level={level}
                                puedeEliminar={puedeEliminar}
                                puedeEditar={puedeEditar}
                                users={state.data}
                                onDeleteUser={handleDeleteUser}
                               modalIsOpen={stateModal.modalIsOpen}
                               handleOpenModal={handleOpenModal}
                               handleCloseModal={handleCloseModal}
                               handleClickEdit={handleClickEdit}
                                />
                            </div>
                        </div>
                </div>
            </React.Fragment>
        
    );
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(Users);

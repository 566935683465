import React,{useEffect} from 'react'
import {Modal, Button } from 'react-bootstrap';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOsm from 'ol/source/OSM';
import OlFeature from 'ol/Feature';

import OlOverlay from "ol/Overlay";
import { fromLonLat,toLonLat ,transform} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OLPoint from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/LineString';
import {Fill, Stroke,Style, Text,Icon} from 'ol/style'
import {toStringHDMS} from 'ol/coordinate';


const ModalMapa = (props) =>{
    const {longitude,latitude,fecha,titulo} =props;
    const { innerWidth: width, innerHeight: height } = window;
    


    var rasterLayer = new OlLayerTile({
        name: 'OSM',
        source: new OlSourceOsm()
    });

    //21.066288,
    //"longitude": -101.564705

    const map = new OlMap({
        layers: [rasterLayer],
       // overlays: [overlay],
        view: new OlView({
          center: fromLonLat([longitude,latitude]),
          zoom: 16
        })
    });

    const addMarkers = () => {
        var webMercator = fromLonLat([longitude, latitude])
        var iconFeature = new OlFeature({
            geometry: new OLPoint(webMercator),
            name: `${fecha}`,
            population: 4000,
            rainfall: 500
        });

        var iconStyle = [
            new Style({
                image: new Icon(/** @type {module:ol/style/Icon~Options} */({
                        anchor: [0.5, 1],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: require('../images/map_marker.png')
                }))
            }),
            new Style({
                text: new Text({
                    text: `${fecha}`,
                    offsetY: -7,
                    scale: 1.2,
                    fill: new Fill({
                        color: "#fff"
                    }),
                    stroke: new Stroke({
                        color: "0",
                        width: 3
                      })
                })
            })
        ];

        iconFeature.setStyle(iconStyle);

        var vectorSource = new VectorSource({
            features: [iconFeature]
        });

        var vectorLayer = new VectorLayer({
            source: vectorSource
        });

        map.addLayer(vectorLayer);
       

    }
    

    useEffect( () => {
        console.log('useEffect MO')
        map.setTarget('divMap');
        addMarkers()
        
        


        
    },[longitude]);

    return (
        <Modal show={props.isOpen} onHide={props.onClose}>
                <Modal.Header closeButton>
                <Modal.Title>Localización {titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            
                        
                            <div id="divMap" style={{ width: `100%`, height: `${height / 2}px` }} />
                            
                            
                    
                
                        
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
         

    )
}
export default ModalMapa;
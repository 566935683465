import React ,{useState,useEffect}from 'react';
import {connect} from 'react-redux';
import Select from 'react-select'
import { useForm } from "react-hook-form";
import {URL_BASE_WS} from '../Constanst'
import {estados,municipios} from '../Constanst'
import MiniLoader from './MiniLoader'
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTimes} from "@fortawesome/free-solid-svg-icons";


import qs from 'querystring'


const   CustomerForm = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    
    
    const [state,setState] = useState({
        selectedEstado: 0,
        selectedMunicipio: 0,
        municipiosFilter : [],
        loading :false,
        error: null
    });

    useEffect( () => {
        if(hasUser===false){
            props.history.replace('/');
        }
        
    });


    const onSubmit = async data =>{
        //e.preventDefault();
        console.log(`state.selectedEstado:${state.selectedEstado}`)
        data['stateId'] = state.selectedEstado;
        data['localityId'] = state.selectedMunicipio;;

        
        if(data.logo[0] !== undefined){
            let idCardBase64 = await getBase64(data.logo[0]);
            data['logo']=idCardBase64;
        }
        
        
        setState({
            ...state,
            loading:true
        })

        
        await fetch(`${URL_BASE_WS}/clients`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': user.apikey
            },
            body:  qs.stringify(data),
        }).then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(function(data) {
            if(data.error){
                
                        swal("Algo salio mal!", `${data.message}`, "error")
                    
                setState({
                    ...state,
                    loading:false,
                })
            } else {
                setState({
                    ...setState,
                    loading:false,
                })
                props.history.push('/customers');
            }
        }).catch(function(error) {
            console.log(error);
            setState({
                ...state,
                loading:false,
            })
        });

    };

    

    

    const handleChangeEstado  = value => {
        if(value!=null){
            let idEstado = value.value;
            setState({
                ...state,
                selectedEstado:idEstado,
                municipiosFilter:municipios.filter(item => item.estadoId === idEstado)
            });
        }
        
    };
    const handleChangeMunicipio = value => {
        if(value!=null){
            console.log(value);
            setState({
                ...state,
                selectedMunicipio:value.value
            }); 
        }
    };

    
    const getBase64 = (file) => 
    new Promise( (resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            reject(Error("Promise "+error));
        };

    })
    
    const [stateFile,setStateFile] = useState({
        file:null
    })

    const onChangeFile = async  (e) => {
        if(e.target.files[0].type.match('image.*')){
            let idCardBase64 = await getBase64(e.target.files[0]); 
            setStateFile({
                file:idCardBase64
            })
        } else {
            setStateFile({
                file:null,
                error: 'Solo Imagenes'
            })
            e.target.value = null;
        }
    };

    const handleClickDeleteImage = (e) =>{

        console.log(document.getElementById('logo'));
        document.getElementById('logo').value = null;
        setStateFile({
            file:null
        })
        

    }




    return(
        
             <div>
                <h1>Cliente</h1>
                <form>
                <div className='container'>
                    <fieldset><strong>Generales</strong></fieldset>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Nombre / Razón Social</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='name' 
                                ref={register({ required: true })} 
                                placeholder="Razon Social" 
                                />
                                {errors.name && ( <span className='text-danger'>Obligatorio</span>)}
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* RFC</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                maxLength="13"
                                name='rfc' 
                                ref={register({ required: true })} 
                                placeholder="RFC" 
                                />
                                {errors.rfc && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Partner Comercial</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='partner' 
                                ref={register({ required: false })} 
                                placeholder="Partner " 
                                
                                />
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-2'>
                            <div className='form-group'>
                                <label>% Comisión</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='partnerComision' 
                                placeholder="Porcentaje Comisión" 
                                ref={register({ required: false ,pattern: /^[0-9]+$/})} 
                                />
                                {errors.partnerComision?.type === "pattern" && ( <span className='text-danger'>Es numerico</span>)} 
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='form-group'>
                                <label>Tiempo de Comisión</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='partnerComisionTiempo' 
                                placeholder="Tiempo Comisión" 
                                ref={register({ required: false ,pattern: /^[0-9]+$/})} 
                                />
                                {errors.partnerComisionTiempo?.type === "pattern" && ( <span className='text-danger'>Es numerico</span>)} 
                            </div>
                        </div>
                        
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Estado</label>
                                <Select 
                                placeholder='Selecciona Estado...'
                                isClearable={true}
                                isSearchable={true}
                                isLoading={false}
                                isDisabled={false}
                                options={estados} 
                                onChange={handleChangeEstado}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Municipio</label>
                                <Select 
                                placeholder='Selecciona Municipio...'
                                isClearable={true}
                                isSearchable={true}
                                isLoading={false}
                                isDisabled={false}
                                options={state.municipiosFilter} 
                                onChange={handleChangeMunicipio}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Colonia</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='subLocality' 
                                ref={register({ required: false })} 
                                placeholder="Colonia" 
                                
                                />
                            </div>
                        </div>
                        
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Calle y Número</label>
                                <input 
                                className='form-control' 
                                ref={register({ required: false })} 
                                type='text' 
                                name='street' 
                                placeholder="Calle" 
                                
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Código Postal</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                maxLength="5"
                                name='postalCode'     
                                ref={register({ required: false ,pattern: /^[0-9]+$/})} 
                                placeholder="Codigo Postal" 
                                />
                                {errors.postalCode?.type === "pattern" && ( <span className='text-danger'>Es numerico</span>)}   
                               
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Logo</label>
                                <input 
                                className='' 
                                type='file' 
                                name='logo' 
                                id='logo'
                                onChange={onChangeFile}
                                ref={register({ required: true, pattern:/[a-zA-Z0-9]\.(jpe?g|gif|png|tiff)$/i })} 
                                placeholder="Logotipo" 
                                
                                />
                                {stateFile.error && ( <span className='text-danger'>Solo Imagenes</span>)}   
                            </div>
                        </div>   
                        
                        
                    </div>
                    {
                        stateFile.file && (
                            <div className='row'>
                                <div className='col-4'>
                                <FontAwesomeIcon icon={faTimes}  className='pointer ml-3 text-danger' onClick={handleClickDeleteImage}/>
                                       <br/> <img src={stateFile.file} alt="Logo"  className="img-thumbnail" />
                                </div> 
                                
                            </div>
                        )

                    }
                    
                   
                    <fieldset><strong>Contacto</strong></fieldset>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Contacto</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='contact' 
                                ref={register({ required: false })} 
                                placeholder="Nombre de Contacto" 
                                
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>Teléfono</label>
                                <input 
                                className='form-control' 
                                type='text' 
                                name='phoneNumber' 
                                maxLength="10"
                                ref={register({ required: false,pattern: /^[0-9]+$/ })} 
                                placeholder="Telefono de Contacto" 
                                />
                                {errors.phoneNumber?.type === "pattern" && ( <span className='text-danger'>Es numerico</span>)} 
                            </div>
                        </div>
                        
                    </div>
                    <fieldset><strong>Información de Credenciales</strong></fieldset>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Cantidad de Usuarios</label>
                                <input 
                                className='form-control' 
                                required
                                type='text' 
                                name='maxUsers' 
                                ref={register({ required: true , pattern: /^[0-9]+$/})} 
                                placeholder="Numero de Usuarios" 
                                />
                                {errors.maxUsers?.type === "required" && ( <span className='text-danger'>Obligatorio</span>)}
                                {errors.maxUsers?.type === "pattern" && ( <span className='text-danger'>Es numerico</span>)}
                                

                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Usuario</label>
                                <input 
                                className='form-control' 
                                required
                                type='text' 
                                name='username' 
                                ref={register({ required: true })} 
                                placeholder="Usuario de Sistema" 
                                />
                                {errors.username && ( <span className='text-danger'>Obligatorio</span>)}
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Password</label>
                                <input 
                                className='form-control' 
                                required
                                type='password' 
                                name='password' 
                                ref={register({ required: true })} 
                                placeholder="Contraseña" 
                                />
                                {errors.password && ( <span className='text-danger'>Obligatorio</span>)}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    state.error && (
                        <p className="d-flex justify-content-center forgot-password text-right text-danger" >{state.error}</p>
                    )
                }
                <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'>

                        {
                            state.loading?
                            <div className="d-flex justify-content-center"><MiniLoader/></div>:
                            <button type='button' 
                            onClick={handleSubmit(onSubmit)}
                            className="btn btn-primary mt-4">
                                Guardar
                            </button>
                        }
                            
                        </div>
                </div>
            </form>
           
        </div>
        

    );
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(CustomerForm);
import React from 'react';

import ReportCustomerListItem from './ReportCustomerListItem';

const  ReportCustmerList = (props) => {
    const {reportList} = props;
    
        
        if (reportList.length === 0) {
            return (
              <div>
                <h3>No hay datos para el reporte de Clientes.</h3>
              </div>
            );
        }


        return(
            <table className="table table-bordered">
            <thead className="thead-dark">
                    <tr>
                    
                    <th scope="col">Cliente</th>
                    <th scope="col">Usuarios Autorizados</th>
                    <th scope="col">Usuarios Activos</th>
                    <th scope="col">Usuarios Pendientes</th>
                    <th scope="col">Fecha de Activación</th>
                    <th scope="col">Estatus</th>
                    
                    
                    </tr>
                </thead>
                <tbody>
                {reportList.map((item, index) => {
                    return (
                    
                        <ReportCustomerListItem 
                        data={item}
                         index={index}  />
                    
                    );
                })}


                </tbody>
            </table>
        )
    

}
export default ReportCustmerList;
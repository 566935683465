import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import PageLoading from '../components/PageLoading';
import swal from 'sweetalert';
import Swal2 from 'sweetalert2'
import PanicHistoryList from '../components/PanicHistoryList'
import qs from 'querystring'
import ModalMapa from '../components/ModalMapa';

const  PanicHistory = (props) => {
    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const level = user.level;
    const puedeEditar = user.level<2 ? true :   (user.permissions & 2048) !== 0 ? true : false;

    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    });

    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
    const [endDate, setEndDate] = useState(new Date());
    const [params, setParams] = useState('');
    const now = new Date();
    
    const handleStarDate = (date) =>{
        if(date > endDate){
            setEndDate(date)
        } else {
            const limiteEnd = new Date(new Date(date).setDate(date.getDate() + 30))
            if(endDate > limiteEnd){
                setEndDate(limiteEnd)
            }
        }
        setStartDate(date);
    }
    const handleEndDate = (date) =>{
        if (date < startDate) {
            setStartDate(date)
        } else {
            const minimoStart = new Date(new Date(date).setDate(date.getDate() - 30))
            if (startDate < minimoStart) {
                setStartDate(minimoStart)
            }
        }
        setEndDate(date);

    }
    
    const handleSyncFecha = () => {
        if (startDate !== undefined && endDate !== undefined) {
            if (startDate <= endDate) {
                setParams(`?startDate=${formattedDate(startDate)}&endDate=${formattedDate(endDate)}`);
            } else {
                swal("Algo salio mal!", 'Revisa los valores de fechas', "error")
            }
        } else {
            swal("Algo salio mal!", 'Define fechas de busqueda', "error")
        }
    }

    function formattedDate(d = new Date) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        return `${day}/${month}/${year}`;
    }
   
    async function fetchAlerts() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/alerts${params}`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                
                    props.history.replace('/');
                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.alerts})
        }
    }

    const handleClickLocation = (e) => {
        e.preventDefault()
        let index  = Number(e.currentTarget.dataset.index);
        
        setModal({
            isOpen:true,
            latitude: state.data[index].latitude,
            longitude: state.data[index].longitude,
            fecha:state.data[index].date,
            titulo:state.data[index].userName
            
        })
    }
    
    const handleClickEdit = async (e) => { 
        let index  = Number(e.currentTarget.dataset.index);
        let alertId = state.data[index].alertId
        let htmlObservations = "<div style=\"font-size: medium; text-align: left; max-height: 250px; overflow: auto\">"
        if (state.data[index].observations != null) {
            state.data[index].observations.map((observation, index) => {
                htmlObservations += "<a><b>" + observation.userName + " (" + observation.dateTime + ")</b></a><br>" + "<pre>" + observation.observation + "</pre>";
            });
        }
        htmlObservations += "</div>"
        //attendedObservation
        await  Swal2.fire({
            title: "Modificar Alerta",
            html: "<h4>Observaciones:</h4>" + htmlObservations,
            input: 'textarea',
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: (value) => {
               if (value) {
                   return value;
                } else {
                  Swal2.showValidationMessage('Falta Observación')   
                }
              }
            }).then((result) => {
                if(result.isConfirmed===true){
                    (async () => {
                        let formJson={
                            attendedObservation: result.value,
                            alertId:alertId
                        }
                        console.log(formJson);
                        let response =  await fetch(`${URL_BASE_WS}/alerts`, {
                            method: 'put',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                                'Authorization': user.apikey
                            },
                            body:  qs.stringify(formJson),
                        });
                        response = await  response.json();
                        console.log(response);
                        if(response.error === true){
                            swal("Algo salio mal!", `${response.message}`, "error")
                        } else {
                            fetchAlerts();
                        }
                    })();
                }
            });
    }
    const handleClickDetail = async (e) => {
        let index  = Number(e.currentTarget.dataset.index);
        let htmlObservations = "<div style=\"font-size: medium; text-align: left; max-height: 250px; overflow: auto\">"
        if (state.data[index].observations != null) {
            state.data[index].observations.map((observation, index) => {
                htmlObservations += "<a><b>" + observation.userName + " (" + observation.dateTime + ")</b></a><br>" + "<pre>" + observation.observation + "</pre>";
            });
        }
        htmlObservations += "</div>"
        await Swal2.fire({
            title: "Detalles Alerta",
            html: "<h4>Observaciones:</h4>" + htmlObservations,
        });
    }
/************MOdal******** */
    const [modal,setModal] = useState({
        isOpen: false,
        longitude:0,
        latitude:0
    })

    const handleCloseModal = e => {
        setModal({ isOpen: false,
            longitude:0,
            latitude:0 });
    };


    useEffect( () => {
        console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchAlerts();
    },[params]);

    if (state.loading === true) {
        return <PageLoading />;
      }
    
    return(
           <React.Fragment>
                <div className='container'>
                <div className='row mb-3 mt-4 justify-content-start'>
                        <div className='col-6'>
                            <h2>Historial Botón de Panico</h2>
                        </div>
                        <div className='col-4 '></div>
                        <div className='col-4 '>
                        
                            
                        </div>
                    </div>
                    
                    <div className='row justify-content-center'>
                            <div className='col-6'>
                                <PanicHistoryList
                                    level={level}
                                    puedeEditar={puedeEditar}
                                    loading={state.loading}
                                    handleSyncFecha={handleSyncFecha}
                                    handleStarDate={handleStarDate}
                                    handleEndDate={handleEndDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    panics={state.data}
                                    handleClickLocation={handleClickLocation}
                                    handleClickEdit={handleClickEdit}
                                    handleClickDetail={handleClickDetail}
                                    apikey={user.apikey}
                                />
                            </div>
                        </div>
                </div>

                <ModalMapa
                longitude={modal.longitude}
                latitude={modal.latitude}
                fecha={modal.fecha}
                titulo={modal.titulo}
                onClose={handleCloseModal}
                isOpen={modal.isOpen}
                />
            </React.Fragment>
    );
    
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(PanicHistory);
import React from 'react';

import UserRouteList from '../components/UserRouteList'

class RouteNew extends React.Component {

    constructor(props){
        super(props);
       
        this.state = {
            loading: true,
            error: null,
            dataSelec: [],
            data: [
                {id:1,nombre:'Juan Luis Perez Diaz',cliente:'Bodega Aurrera',direccion:'Villas de Gto'},
                {id:2,nombre:'Emanuel Rocha Luna',cliente:'Fracc. Villas de Guanajuato',direccion:'Villas de Gto'},
                {id:3,nombre:'Carlos Ruiz Palacion',cliente:'Universidad Santa Fe',direccion:'Villas de Gto'},
                {id:4,nombre:'Rodrigo Gomez Rosales',cliente:'Fracc. Manantial',direccion:'Villas de Gto'}
            ],
            //data: undefined,
          };

    }

    handleClickUser = (e) => {
        let index  = e.currentTarget.dataset.id;
        if (index !== -1) {
            let newValue=this.state.data[e.currentTarget.dataset.id];
            this.setState(previousState => ({
                dataSelec: [...previousState.dataSelec, newValue]
            }));
        
            var prevData = [...this.state.data];
        
            prevData.splice(index, 1);
            this.setState({data: prevData});
        }
    }

    handleClickSelec = (e) => {
        let index  = e.currentTarget.dataset.id;
        if (index !== -1) {
            let newValue=this.state.dataSelec[e.currentTarget.dataset.id];
            this.setState(previousState => ({
                data: [...previousState.data, newValue]
            }));
        
            var prevData = [...this.state.dataSelec];
        
            prevData.splice(index, 1);
            this.setState({dataSelec: prevData});
        }
    }

    


    render (){
        return(
            <React.Fragment>
                <div className='container'>

                    <div className='row mt-1'>
                        <div className='col-5'>
                            <h1>Recorrido</h1>
                        </div>
                    </div>

                    <div className='row mt-1'>
                        
                        <div className='col-5'>
                            <div className='row'>
                                <div className='col'>
                                    <h6>Agrega Usuarios para Recorrido...</h6>
                                </div>
                            </div>    
                            <div className='row'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label>
                                            <svg className="bi bi-search text-dark mr-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                                <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                            </svg>
                                            Buscar
                                        </label>
                                        <input 
                                        className='form-control' 
                                        type='text' 
                                        name='name' 
                                        //value={this.props.formValues.rfc}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                    <div className='col'>
                                        <UserRouteList onClick={this.handleClickUser} users={this.state.data} className='card bg-info mb-3 pointer'/>
                                    </div>
                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-5'>
                            <div className='row  mb-2'>
                                <div className='col-6'></div>
                                <div className='col-6'>
                                    <button className='btn btn-primary btm-sm'>Solicitar Recorrido</button>
                                </div>
                            </div>    
                            <div className='row'>
                                    <div className='col'>
                                        <UserRouteList onClick={this.handleClickSelec} users={this.state.dataSelec} className='card bg-secondary mb-3 pointer'/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}
export default RouteNew;
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch} from "@fortawesome/free-solid-svg-icons";
import FiltroFechas from './FiltroFechas';

const ActivityGSearchForm = (props) => {
    const {startDate,endDate,handleStarDate,handleEndDate,handleSearch,puedeConsultar}=props;

    return(
        <div>
            <h1>Actividades</h1>
            <form>
                <div className='row'>
                    <div className='col-3' />
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Fecha</label>
                            <FiltroFechas 
                                handleStarDate={handleStarDate}
                                handleEndDate={handleEndDate}
                                startDate={startDate}
                                endDate={ endDate}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-5'></div>
                    <div className='col-2 '>
                    {
                        puedeConsultar===true && (
                            <button 
                                type='button' 
                                className='btn btn-secondary'
                                onClick={handleSearch}
                            >
                                <FontAwesomeIcon icon={faSearch} />  Buscar
                            </button>
                        )
                    }
                    </div>
                </div>
            </form>
        </div>
    )
}
export default ActivityGSearchForm;